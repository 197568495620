import { Role } from '../types'

export enum LoadingKeyType {
  Garages = 'garages',
  Users = 'users',
  Warranty = 'warranty',
}

export abstract class LoadingKey {
  protected key: string

  protected constructor(type: LoadingKeyType) {
    this.key = type
  }

  public toString(): string {
    return this.key
  }
}

export class GaragesKey extends LoadingKey {
  constructor() {
    super(LoadingKeyType.Garages)
  }
}

export class UsersKey extends LoadingKey {
  constructor(role: Role) {
    super(LoadingKeyType.Users)
    this.key = `${this.key}-${role.slug}`
  }
}

export class WarrantyKey extends LoadingKey {
  constructor(id: string) {
    super(LoadingKeyType.Warranty)
    this.key = `${this.key}-${id}`
  }
}

export class LoadingService {
  private loading: string[] = []
  private nextID: number = 0
  private subscribers: Array<{
    id: number
    key: string
    onChange: (loading: boolean) => void
  }> = []

  public isLoading(key: LoadingKey): boolean {
    return this.loading.includes(key.toString())
  }

  public toggle(key: LoadingKey, loading: boolean): void {
    const index = this.loading.indexOf(key.toString())
    if (loading) {
      if (index < 0) {
        this.loading.push(key.toString())
        this.subscribers.forEach((subscriber) => {
          if (subscriber.key === key.toString()) {
            subscriber.onChange(true)
          }
        })
      }
    } else {
      if (index >= 0) {
        this.loading.splice(index, 1)
        this.subscribers.forEach((subscriber) => {
          if (subscriber.key === key.toString()) {
            subscriber.onChange(false)
          }
        })
      }
    }
  }

  public subscribe(
    key: LoadingKey,
    onChange: (loading: boolean) => void,
  ): number {
    const id = this.nextID
    this.nextID++
    this.subscribers.push({ id, key: key.toString(), onChange })
    onChange(this.isLoading(key))
    return id
  }

  public unsubscribe(id: number): void {
    const index = this.subscribers.findIndex(
      (subscriber) => subscriber.id === id,
    )
    if (index >= 0) {
      this.subscribers.splice(index, 1)
    }
  }
}
