import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'
import { FormField, FormFieldExtras } from '../../../../types'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

export interface FieldConfig {
  displayLabel?: boolean
  isDisabled?: boolean
  isReadOnly?: boolean
  showPasswords?: boolean
  onTogglePasswords?: (show: boolean) => void
}

export interface FieldProps extends FieldConfig {
  field: FormField
  extras?: FormFieldExtras
}

export function BaseField<T extends object>({
  field,
  extras,
  isDisabled = false,
  isReadOnly = false,
  displayLabel = true,
  children,
}: PropsWithChildren<FieldProps>) {
  const {
    formState: { errors },
  } = useFormContext<T>()

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <FormControl
      as={GridItem}
      colSpan={field.colSpan ?? 1}
      isInvalid={!!((errors as any)[field.name] as FormField | undefined)?.type}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      {...(extras?.isHidden ? { display: 'none' } : {})}
    >
      {displayLabel ? (
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textPrimary}
          mb="8px"
        >
          {field.label} {field.requiredError ? '*' : ''}
        </FormLabel>
      ) : null}
      {children}
      <ErrorMessage
        errors={errors}
        name={field.name as any}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
