import React, { Key } from 'react'
import { FormConfig, FormFieldExtras, FormFieldType } from '../../../../types'
import { TextField } from '../../molecules/forms/TextField'
import { FieldConfig, FieldProps } from '../../molecules/forms/BaseField'
import { PasswordField } from '../../molecules/forms/PasswordField'
import { Grid } from '@chakra-ui/react'
import { Spacer } from '../../molecules/forms/Spacer'
import { SelectField } from '../../molecules/forms/SelectField'
import { TextareaField } from '../../molecules/forms/TextareaField'
import { DateField } from '../../molecules/forms/DateField'

type RenderFieldProps = FieldProps & { key: Key }

const fieldTypes = {
  [FormFieldType.Text]: (props: RenderFieldProps) => <TextField {...props} />,
  [FormFieldType.Textarea]: (props: RenderFieldProps) => (
    <TextareaField {...props} />
  ),
  [FormFieldType.Email]: (props: RenderFieldProps) => <TextField {...props} />,
  [FormFieldType.Password]: (props: RenderFieldProps) => (
    <PasswordField {...props} />
  ),
  [FormFieldType.Select]: (props: RenderFieldProps) => (
    <SelectField {...props} />
  ),
  [FormFieldType.Date]: (props: RenderFieldProps) => <DateField {...props} />,
  [FormFieldType.DateTime]: (props: RenderFieldProps) => (
    <DateField {...props} includeTime />
  ),
  [FormFieldType.Spacer]: (props: RenderFieldProps) => <Spacer {...props} />,
}

const GridForm: React.FunctionComponent<
  {
    config: FormConfig
    extras?: { [key: string]: FormFieldExtras }
  } & FieldConfig
> = (props) => {
  const { config, extras, ...fieldProps } = props
  return (
    <Grid
      templateColumns={{ sm: '1fr', md: `repeat(${config.columns ?? 1}, 1fr)` }}
      gap="1rem"
      alignContent="flex-start"
    >
      {config.fields.map((field, index) =>
        fieldTypes[field.type]({
          field,
          key: index,
          extras: extras && extras[field.name] ? extras[field.name] : undefined,
          ...fieldProps,
        }),
      )}
    </Grid>
  )
}

export default GridForm
