import * as React from 'react'
import { useCallback, useContext, useMemo, useState } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '../../molecules/shared/DataTable'
import { Product, ProductTariff } from '../../../../types'
import { accessorColumn, formatCurrency, formatMonths } from '../../../../utils'
import Actions from '../../molecules/shared/Actions'
import { MdAdd, MdEdit, MdPause, MdPlayArrow } from 'react-icons/md'
import TariffModal from './TariffModal'
import TableButtons from '../../molecules/shared/TableButtons'
import { ServiceContext } from '../../../../components/Services'
import { useToast } from '@chakra-ui/react'

type TariffRow = {
  record: ProductTariff
  duration: string
  claimLimit: string
  grossPrice: string
  vat: string
  netPrice: string
  adminFee: string
  premium: string
  plan: string
  initial: string
}

const TariffsTable: React.FunctionComponent<{
  product: Product
  onProductUpdated: (product: Product) => void
  loading: boolean
}> = ({ product, onProductUpdated, loading }) => {
  const services = useContext(ServiceContext)
  const toast = useToast()
  const [selectedTariff, setSelectedTariff] = useState<
    ProductTariff | undefined
  >(undefined)
  const [showForm, setShowForm] = useState(false)
  const [toggling, setToggling] = useState(false)

  const handlePause = useCallback(
    (tariff: ProductTariff) => {
      setToggling(true)
      services.product
        .updateTariff(product, tariff.id, { paused: !tariff.paused_at })
        .then((product) => {
          setToggling(false)
          onProductUpdated(product)
        })
        .catch(() => {
          setToggling(false)
          toast({
            title: 'Save Failed',
            description:
              'Sorry there was a problem saving your changes to this tariff',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    },
    [product, onProductUpdated, services, toast],
  )

  const rows = useMemo<TariffRow[]>(
    () =>
      (product.tariffs ?? []).map((tariff: ProductTariff) => ({
        record: tariff,
        duration: formatMonths(tariff.duration),
        claimLimit: tariff.claim_limit
          ? formatCurrency(tariff.claim_limit)
          : 'N/A',
        grossPrice: formatCurrency(tariff.gross_price),
        vat: formatCurrency(tariff.vat),
        netPrice: formatCurrency(tariff.net_price),
        adminFee: formatCurrency(tariff.admin_fee),
        premium: formatCurrency(tariff.premium),
        plan: tariff.plan.name,
        initial: formatCurrency(tariff.initial),
      })),
    [product],
  )

  const columnHelper = createColumnHelper<TariffRow>()
  const options = { faded: (row: TariffRow) => !!row.record.paused_at }
  const columns = [
    accessorColumn<TariffRow, string>(
      columnHelper,
      'duration',
      'Duration',
      options,
    ),
    accessorColumn<TariffRow, string>(
      columnHelper,
      'claimLimit',
      'Claim Limit',
      options,
    ),
    accessorColumn<TariffRow, string>(
      columnHelper,
      'grossPrice',
      'Gross Price',
      options,
    ),
    accessorColumn<TariffRow, string>(columnHelper, 'vat', 'VAT', options),
    accessorColumn<TariffRow, string>(
      columnHelper,
      'netPrice',
      'Net Price',
      options,
    ),
    accessorColumn<TariffRow, string>(
      columnHelper,
      'adminFee',
      'Admin Fee',
      options,
    ),
    accessorColumn<TariffRow, string>(
      columnHelper,
      'premium',
      'Premium',
      options,
    ),
    accessorColumn<TariffRow, string>(columnHelper, 'plan', 'Plan', options),
    accessorColumn<TariffRow, string>(
      columnHelper,
      'initial',
      'Deposit',
      options,
    ),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              {
                title: 'Edit',
                icon: MdEdit,
                onClick: () => {
                  setSelectedTariff(cell.row.original.record)
                  setShowForm(true)
                },
              },
              {
                title: cell.row.original.record.paused_at ? 'Resume' : 'Pause',
                icon: cell.row.original.record.paused_at
                  ? MdPlayArrow
                  : MdPause,
                onClick: () => {
                  handlePause(cell.row.original.record)
                },
              },
            ]}
          />
        ),
        [handlePause],
      ),
    }),
  ]

  return (
    <>
      <DataTable
        meta={null}
        rows={rows}
        columns={columns as any}
        loading={loading || toggling}
        canSearch={false}
        actions={
          <TableButtons
            buttons={[
              {
                title: 'Add New Tariff',
                icon: MdAdd,
                onClick: () => {
                  setSelectedTariff(undefined)
                  setShowForm(true)
                },
              },
            ]}
          />
        }
      />
      <TariffModal
        tariff={selectedTariff}
        open={showForm}
        onClose={() => setShowForm(false)}
      />
    </>
  )
}

export default TariffsTable
