import type {
  GarageResponse,
  MorphRelation,
  MorphRelationResponse,
  UserResponse,
  WarrantyResponse,
} from '../types'
import { mapUser } from './auth'
import { mapGarage } from './garages'
import { mapWarranty } from './warranties'

export const mapMorphRelation = (
  type: string,
  data: MorphRelationResponse | null,
): MorphRelation | null => {
  if (data) {
    switch (type) {
      case 'garage':
        return mapGarage(data as GarageResponse)
      case 'warranty':
        return mapWarranty(data as WarrantyResponse)
      case 'user':
        return mapUser(data as UserResponse)
    }
  }
  return null
}
