const routes = {
  // Access
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  setPassword: '/auth/set-password',

  // Admin
  dashboard: '/',

  profile: '/profile',

  warranties: {
    index: '/warranties',
    import: '/warranties/import',
    assign: '/warranties/assign',
    detail: '/warranties/:warrantyId',
    renew: '/warranties/:warrantyId/renew',
  },

  garages: {
    index: '/garages',
    add: '/garages/add',
    detail: '/garages/:garageId',
  },

  admin: {
    index: '/admin',
  },

  products: {
    index: '/admin/products',
    add: '/admin/products/add',
    detail: '/admin/products/:productId',
  },

  users: {
    index: '/admin/users',
    add: '/admin/users/add',
    detail: '/admin/users/:userId',
  },

  archive: {
    users: '/archive/users',
  },
}

export default routes
