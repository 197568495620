import { QueryParams, URLQuery } from '../types'

export const serialiseTableParams = (params: QueryParams): URLQuery => {
  const { page, search, sort, garage, tab, user, type, duration, status } =
    params
  return {
    ...(page ? { page: `${params.page}` } : {}),
    ...(search ? { search } : {}),
    ...(sort ? { sort } : {}),
    ...(garage !== undefined
      ? { garage: garage.map((id) => `${id ?? 0}`).join(',') }
      : {}),
    ...(tab !== undefined ? { tab } : {}),
    ...(user !== undefined ? { user: `${user ?? 0}` } : {}),
    ...(type !== undefined ? { type: type.join(',') } : {}),
    ...(duration !== undefined
      ? { duration: duration.map((value) => `${value ?? 0}`).join(',') }
      : {}),
    ...(status !== undefined ? { status: status.join(',') } : {}),
  }
}

export const deserialiseTableParams = (query: URLQuery): QueryParams => {
  const { page, search, sort, garage, tab, user, type, duration, status } =
    query
  return {
    page: page ? parseInt(page) : undefined,
    search: search ?? undefined,
    sort: sort ?? undefined,
    garage: garage ? garage.split(',').map((id) => parseInt(id)) : undefined,
    tab,
    user: user ? parseInt(user) : undefined,
    type: type ? type.split(',') : undefined,
    duration: duration
      ? duration.split(',').map((value) => parseInt(value))
      : undefined,
    status: status ? status.split(',') : undefined,
  }
}
