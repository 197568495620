import React, { useCallback, useContext } from 'react'
import { useToast } from '@chakra-ui/react'
import { UseFormSetValue } from 'react-hook-form'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import FormCard from '../../molecules/shared/FormCard'
import { MANAGEMENT, userCanAccess } from '../../../../utils'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { useWarranty } from '../../../../hooks'
import { warrantyAddress, WarrantyAddressFields } from '../../../../forms'
import GridForm from '../forms/GridForm'
import { mapWarrantyAddressFields } from '../../../../mappers'

const WarrantyAddress: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const user = useSelector(getUser)
  const { warranty, onUpdate } = useWarranty()

  const initialise = useCallback(
    (setValue: UseFormSetValue<WarrantyAddressFields>) => {
      setValue('line1', warranty.address_1)
      setValue('line2', warranty.address_2)
      setValue('line3', warranty.address_3)
      setValue('line4', warranty.address_4)
      setValue('postcode', warranty.postcode)
    },
    [warranty],
  )

  const renderFields = useCallback(
    (disabled: boolean, readOnly: boolean) => (
      <GridForm
        config={warrantyAddress}
        isDisabled={disabled}
        isReadOnly={readOnly}
      />
    ),
    [],
  )

  const onSubmit = useCallback(
    (
      input: WarrantyAddressFields,
      setError: (error: string) => void,
      onLoading: (loading: boolean) => void,
      onSaved: () => void,
    ) => {
      setError('')
      onLoading(true)
      services.warranty
        .update(warranty.id, mapWarrantyAddressFields(input))
        .then((updated) => {
          onLoading(false)
          onUpdate(updated)
          onSaved()
          toast({
            title: 'Warranty Updated',
            description: `Your changes to ${warranty.full_name}'s address have been successfully saved`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          onLoading(false)
          setError('Sorry, there was a problem saving the details you entered')
        })
    },
    [services, toast, warranty, onUpdate],
  )

  return (
    <FormCard<WarrantyAddressFields>
      title="Customer Address"
      initialise={initialise}
      renderFields={renderFields}
      onSubmit={onSubmit}
      readOnly={!userCanAccess(user, MANAGEMENT)}
    />
  )
}

export default WarrantyAddress
