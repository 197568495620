import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useWarranty } from '../../../../hooks'
import { warrantyVehicle, WarrantyVehicleFields } from '../../../../forms'
import GridForm from '../forms/GridForm'

const RenewWarrantyVehicle: React.FunctionComponent<{
  values: WarrantyVehicleFields
}> = ({ values }) => {
  const { warranty } = useWarranty()
  const { setValue, reset } = useFormContext<WarrantyVehicleFields>()

  useEffect(() => {
    if (values) {
      reset(values)
    } else {
      setValue('registration', warranty.vehicle_registration)
      setValue('make', warranty.make)
      setValue('model', warranty.model)
      setValue('mileage', `${warranty.mileage ?? ''}`)
      setValue('price', `${warranty.price ?? ''}`)
    }
  }, [values, warranty, setValue, reset])

  return <GridForm config={warrantyVehicle} />
}

export default RenewWarrantyVehicle
