import React, { useState } from 'react'
import WarrantyAdminSummary from '../warranties/WarrantyAdminSummary'
import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { useWarrantyTableData } from '../../../../hooks'
import WarrantiesTable from '../warranties/WarrantiesTable'
import Card from '../../../../horizon/components/card/Card'
import TableButtons from '../../molecules/shared/TableButtons'
import { MdSupervisedUserCircle } from 'react-icons/md'
import routes from '../../../../routes'
import { TELESALES } from '../../../../utils'
import UserMenu from '../../molecules/users/UserMenu'
import { User } from '../../../../types'
import AgentDashboard from './AgentDashboard'

const ManagementDashboard: React.FunctionComponent = () => {
  const queryParamKey = 'managementDashboard'

  const { warranties, loadingWarranties } = useWarrantyTableData(
    {
      unassigned: true,
      summary: true,
    },
    true,
    queryParamKey,
  )
  const [user, setUser] = useState<User | null>(null)

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Flex direction="column" marginBottom="2rem">
      <Flex alignItems="center" gap="1rem">
        <Text paddingLeft="2rem">Data for:</Text>
        <UserMenu
          selected={user}
          label={user ? user.full_name : 'All Agents'}
          onSelect={setUser}
          role={TELESALES}
          additionalOptions={{
            before: [
              {
                label: 'All Agents',
                selected: !user,
                onSelect: () => setUser(null),
              },
            ],
          }}
        />
      </Flex>
      {user ? (
        <AgentDashboard user={user} />
      ) : (
        <>
          <WarrantyAdminSummary
            summary={warranties?.meta?.summary}
            loading={loadingWarranties}
          />
          <Card padding="1rem" display="flex" flexDirection="column" gap="1rem">
            <Text
              fontSize="xl"
              color={textPrimary}
              fontWeight="bold"
              paddingX="0.5rem"
            >
              Unassigned Warranties
            </Text>
            <WarrantiesTable
              data={warranties}
              loading={loadingWarranties}
              queryParamKey={queryParamKey}
              actions={
                <TableButtons
                  buttons={[
                    {
                      title: 'Assign',
                      icon: MdSupervisedUserCircle,
                      route: routes.warranties.assign,
                    },
                  ]}
                />
              }
            />
          </Card>
        </>
      )}
    </Flex>
  )
}

export default ManagementDashboard
