import { ProductTariff } from '../types'
import { formatCurrency } from './currency'

export const formatFurtherPayments = (tariff: ProductTariff) => {
  if (tariff.plan?.value === 1) {
    return 'No Further Payments'
  } else if (
    tariff.plan &&
    (tariff.gross_price ?? 0) > 0 &&
    (tariff.initial ?? 0) > 0
  ) {
    const balance = tariff.gross_price - tariff.initial
    const remaining = tariff.plan.value - 1
    return `${remaining} further payments of ${formatCurrency(balance / remaining)} per month`
  }
  return 'N/A'
}
