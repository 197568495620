import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useWarranty } from '../../../../hooks'
import { warrantyAddress, WarrantyAddressFields } from '../../../../forms'
import GridForm from '../forms/GridForm'

const RenewWarrantyAddress: React.FunctionComponent<{
  values: WarrantyAddressFields
}> = ({ values }) => {
  const { warranty } = useWarranty()
  const { setValue, reset } = useFormContext<WarrantyAddressFields>()

  useEffect(() => {
    if (values) {
      reset(values)
    } else {
      setValue('line1', warranty.address_1)
      setValue('line2', warranty.address_2)
      setValue('line3', warranty.address_3)
      setValue('line4', warranty.address_4)
      setValue('postcode', warranty.postcode)
    }
  }, [values, warranty, setValue, reset])

  return <GridForm config={warrantyAddress} />
}

export default RenewWarrantyAddress
