import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useWarranty } from '../../../../hooks'
import { warrantyCustomer, WarrantyCustomerFields } from '../../../../forms'
import GridForm from '../forms/GridForm'

const RenewWarrantyCustomer: React.FunctionComponent<{
  values: WarrantyCustomerFields
}> = ({ values }) => {
  const { warranty } = useWarranty()
  const { setValue, reset } = useFormContext<WarrantyCustomerFields>()

  useEffect(() => {
    if (values) {
      reset(values)
    } else {
      setValue('title', warranty.title)
      setValue('firstName', warranty.first_name)
      setValue('lastName', warranty.last_name)
      setValue('phone', warranty.phone)
      setValue('mobile', warranty.mobile)
      setValue('email', warranty.email)
    }
  }, [values, warranty, setValue, reset])

  return <GridForm config={warrantyCustomer} />
}

export default RenewWarrantyCustomer
