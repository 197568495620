// Chakra imports
import {
  Avatar,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useUser } from '../../../../hooks'
import Actions from '../../molecules/shared/Actions'
import { MdArchive, MdEmail } from 'react-icons/md'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import ConfirmationModal from '../../molecules/shared/ConfirmationModal'
import { useNavigate } from 'react-router-dom'
import routes from '../../../../routes'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'

const UserHeader: React.FunctionComponent = () => {
  const services = useContext<AvailableServices>(ServiceContext)
  const navigate = useNavigate()
  const toast = useToast()
  const { user } = useUser()
  const currentUser = useSelector(getUser)
  const isNotCurrentUser = useMemo(
    () => user.id !== currentUser.id,
    [user, currentUser],
  )

  const [showSendSetPassword, setShowSendSetPassword] = useState(false)
  const [sendingSetPassword, setSendingSetPassword] = useState(false)

  const handleSendSetPassword = useCallback(() => {
    setSendingSetPassword(true)
    services.user
      .sendSetPassword(
        user.id,
        `${window.location.origin}${routes.setPassword}?token={TOKEN}`,
      )
      .then(() => {
        setSendingSetPassword(false)
        setShowSendSetPassword(false)
        toast({
          title: 'Email Sent',
          description: `A set password email has been sent to ${user.full_name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch(() => {
        setSendingSetPassword(false)
        toast({
          title: 'Problem Sending Email',
          description: `There was a problem sending the email to ${user.full_name}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }, [services, toast, user])

  const [showArchive, setShowArchive] = useState(false)
  const [archiving, setArchiving] = useState(false)

  const handleArchive = useCallback(() => {
    setArchiving(true)
    services.user
      .delete(user.id)
      .then(() => {
        setArchiving(false)
        navigate(routes.users.index)
      })
      .catch(() => setArchiving(false))
  }, [services, navigate, user])

  const textColour = useColorModeValue('secondaryGray.900', 'white')
  const avatarColour = useColorModeValue('navy.800', 'white')
  const avatarTextColour = useColorModeValue('white', 'navy.800')
  return (
    <Card alignItems="center">
      <Flex
        width="100%"
        bgGradient="linear(to-b, brand.400, brand.600)"
        minHeight="127px"
        borderRadius="16px"
      ></Flex>
      <Avatar
        marginX="auto"
        name={user.full_name}
        bg={avatarColour}
        textColor={avatarTextColour}
        height="87px"
        width="87px"
        marginTop="-43px"
        marginBottom="15px"
      />
      <Flex alignItems="center" gap="1rem" justifyContent="center">
        <Text fontSize="2xl" textColor={textColour} fontWeight="700">
          {user.full_name}
        </Text>
        <Actions
          actions={[
            ...(isNotCurrentUser
              ? [
                  {
                    title: 'Send Set Password Email',
                    icon: MdEmail,
                    onClick: () => setShowSendSetPassword(true),
                  },
                ]
              : []),
            {
              title: 'Archive',
              icon: MdArchive,
              onClick: () => setShowArchive(true),
            },
          ]}
        />
      </Flex>
      <ConfirmationModal
        open={showSendSetPassword}
        title="Send set password email to this user?"
        body={`Are you sure you want to send ${user.full_name} a set password email? This will allow them to set a new password on their account.`}
        loading={sendingSetPassword}
        onConfirm={handleSendSetPassword}
        onDeny={() => setShowSendSetPassword(false)}
        onClose={() => setShowSendSetPassword(false)}
      />
      <ConfirmationModal
        open={showArchive}
        title="Archive this user?"
        body={
          isNotCurrentUser
            ? `Are you sure you want to archive ${user.full_name}? This will remove will of their access to the system. They can be restored from the archive.`
            : "You can't archive your own user. Doing so would lock you out of the system."
        }
        loading={archiving}
        onConfirm={isNotCurrentUser ? handleArchive : undefined}
        onDeny={isNotCurrentUser ? () => setShowArchive(false) : undefined}
        onClose={() => setShowArchive(false)}
      />
    </Card>
  )
}

export default UserHeader
