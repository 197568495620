import React, { useCallback, useState } from 'react'
import { ProductTariff } from '../../../../types'
import { Flex } from '@chakra-ui/react'
import FormWrapper from '../../molecules/shared/FormWrapper'
import FormStep from '../../molecules/shared/FormStep'
import WarrantyProductSelection from './WarrantyProductSelection'

const RenewWarrantyProduct: React.FunctionComponent<{
  onSubmit: (tariff: ProductTariff) => void
}> = ({ onSubmit }) => {
  const [tariff, setTariff] = useState<ProductTariff | undefined>(undefined)

  const handleSubmit = useCallback(() => {
    if (tariff) {
      onSubmit(tariff)
    }
  }, [tariff, onSubmit])

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {(props) => (
        <Flex {...props} direction="column">
          <FormStep
            canSubmit={!!tariff}
            renderForm={() => (
              <WarrantyProductSelection
                selectedTariff={tariff}
                onTariffSelected={setTariff}
              />
            )}
          />
        </Flex>
      )}
    </FormWrapper>
  )
}

export default RenewWarrantyProduct
