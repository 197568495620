import { QueryParams } from '../types'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { serialiseTableParams } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { getQueryParams, updateQueryParams } from '../store/queryParams'

export const useQueryParams = (
  key: string = 'url',
): {
  params: QueryParams
  updateParams: (params: QueryParams) => void
} => {
  const dispatch = useDispatch()
  const allParams = useSelector(getQueryParams)
  const setSearchParams = useSearchParams()[1]

  const params = useMemo(() => {
    if (allParams && allParams.hasOwnProperty(key)) {
      return allParams[key]
    } else {
      return {}
    }
  }, [key, allParams])

  const updateParams = useCallback(
    (update: QueryParams) => {
      if (key === 'url') {
        setSearchParams(serialiseTableParams({ ...params, ...update }))
      } else {
        dispatch(updateQueryParams({ key, params: update }))
      }
    },
    [key, dispatch, params, setSearchParams],
  )

  return { params, updateParams }
}
