import * as React from 'react'
import { useCallback, useContext, useMemo, useState } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import SteppedContent from '../../molecules/shared/SteppedContent'
import { userDetails, UserDetailsFields } from '../../../../forms'
import FormWrapper from '../../molecules/shared/FormWrapper'
import FormStep from '../../molecules/shared/FormStep'
import GridForm from '../../organisms/forms/GridForm'
import { Flex, useToast } from '@chakra-ui/react'
import { ServiceContext } from '../../../../components/Services'
import SwitchSelector, {
  SwitchOption,
} from '../../molecules/shared/SwitchSelector'
import { ROLES } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'

const NewUserPage: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const toast = useToast()
  const navigate = useNavigate()
  const [saving, setSaving] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [details, setDetails] = useState<UserDetailsFields | undefined>(
    undefined,
  )
  const roles = useMemo(
    () => ROLES.map(({ name, slug }) => ({ title: name, value: slug })),
    [],
  )
  const [role, setRole] = useState<SwitchOption>(roles[0])

  const handleSave = useCallback(() => {
    setSaving(true)
    services.user
      .create({
        first_name: details.firstName,
        last_name: details.lastName,
        email: details.email,
        role: role.value,
      })
      .then((user) =>
        navigate(reverse(routes.users.detail, { userId: user.id })),
      )
      .catch(() => {
        setSaving(false)
        toast({
          title: 'Problem Saving User',
          description:
            'Sorry there was a problem saving the user, please check the details and try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }, [services, toast, navigate, details, role])

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Administration' },
        { label: 'Users', link: routes.users.index },
        { label: 'Add a New User' },
      ]}
      title="Add a New User"
    >
      <SteppedContent
        currentStep={currentStep}
        steps={[
          {
            title: 'Identity',
            content: (
              <FormWrapper
                onSubmit={(input: UserDetailsFields) => {
                  setDetails(input)
                  setCurrentStep(1)
                }}
              >
                {(props) => (
                  <Flex {...props} direction="column">
                    <FormStep
                      isLoading={saving}
                      renderForm={(disabled: boolean) => (
                        <GridForm config={userDetails} isDisabled={disabled} />
                      )}
                    />
                  </Flex>
                )}
              </FormWrapper>
            ),
          },
          {
            title: 'Role',
            content: (
              <FormWrapper onSubmit={handleSave}>
                {(props) => (
                  <Flex {...props} direction="column">
                    <FormStep
                      onBack={() => setCurrentStep(0)}
                      nextLabel="Submit"
                      renderForm={(disabled: boolean) => (
                        <SwitchSelector
                          options={roles}
                          selected={role}
                          onSelect={setRole}
                          isDisabled={disabled}
                        />
                      )}
                    />
                  </Flex>
                )}
              </FormWrapper>
            ),
          },
        ]}
      />
    </AdminTemplate>
  )
}

export default NewUserPage
