import type { NewNote, NewNoteData, Note, NoteResponse } from '../types'
import { mapUser } from './auth'

export const mapNote = (data: NoteResponse): Note => {
  return {
    ...data,
    user: data.user ? mapUser(data.user) : null,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }
}

export const mapNewNote = (data: NewNote): NewNoteData => {
  return {
    subject_id: data.morph.id,
    subject_type: data.morph.type,
    body: data.body,
  }
}
