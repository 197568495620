import { FormConfig, FormFieldType } from '../types'

export type UpdateTaskFields = {
  type: string
  description: string
  dueDate: Date
}

export const updateTask: FormConfig = {
  fields: [
    {
      name: 'type',
      label: 'Type',
      requiredError: 'Please select a type',
      type: FormFieldType.Select,
    },
    {
      name: 'description',
      label: 'Description',
      type: FormFieldType.Textarea,
    },
    {
      name: 'dueDate',
      label: 'Due Date',
      type: FormFieldType.DateTime,
    },
  ],
}

export type UpdateTaskAdminFields = UpdateTaskFields & {
  assignedTo: number | null
}

export const updateTaskAdmin: FormConfig = {
  fields: [
    ...updateTask.fields,
    {
      name: 'assignedTo',
      label: 'Assigned to',
      type: FormFieldType.Select,
    },
  ],
}
