import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import { Flex, useToast } from '@chakra-ui/react'
import { useWarranty } from '../../../../hooks'
import { reverse } from 'named-urls'
import SteppedContent from '../../molecules/shared/SteppedContent'
import RenewWarrantyProduct from '../../organisms/warranties/RenewWarrantyProduct'
import { ProductTariff } from '../../../../types'
import {
  WarrantyAddressFields,
  WarrantyCustomerFields,
  WarrantyVehicleFields,
} from '../../../../forms'
import FormWrapper from '../../molecules/shared/FormWrapper'
import FormStep from '../../molecules/shared/FormStep'
import RenewWarrantyVehicle from '../../organisms/warranties/RenewWarrantyVehicle'
import RenewWarrantyCustomer from '../../organisms/warranties/RenewWarrantyCustomer'
import RenewWarrantyAddress from '../../organisms/warranties/RenewWarrantyAddress'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import {
  mapWarrantyAddressFields,
  mapWarrantyCustomerFields,
  mapWarrantyVehicleFields,
} from '../../../../mappers'
import { useNavigate } from 'react-router-dom'

const RenewWarrantyPage: React.FunctionComponent = () => {
  const services = useContext<AvailableServices>(ServiceContext)
  const toast = useToast()
  const navigate = useNavigate()
  const { warranty, onUpdate } = useWarranty()

  const [renewing, setRenewing] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [tariff, setTariff] = useState<ProductTariff | undefined>(undefined)
  const [vehicle, setVehicle] = useState<WarrantyVehicleFields | undefined>(
    undefined,
  )
  const [customer, setCustomer] = useState<WarrantyCustomerFields | undefined>(
    undefined,
  )
  const [address, setAddress] = useState<WarrantyAddressFields | undefined>(
    undefined,
  )

  const handleRenew = useCallback(
    (address: WarrantyAddressFields) => {
      setAddress(address)
      setRenewing(true)
      services.warranty
        .startRenewal(warranty.id, {
          tariff_id: tariff.id,
          garage_id: warranty.garage?.id,
          ...mapWarrantyVehicleFields(vehicle),
          ...mapWarrantyCustomerFields(customer),
          ...mapWarrantyAddressFields(address),
        })
        .then((renewed) => {
          setRenewing(false)
          onUpdate(renewed)
          navigate(
            reverse(routes.warranties.detail, { warrantyId: renewed.id }),
          )
        })
        .catch(() => {
          setRenewing(false)
          toast({
            title: 'Problem Renewing Warranty',
            description:
              'Sorry there was a problem renewing this warranty, please check the details and try again',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    },
    [services, toast, navigate, warranty, onUpdate, tariff, vehicle, customer],
  )

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Warranties', link: routes.warranties.index },
        ...(warranty
          ? [
              {
                label: warranty.policy_number,
                link: reverse(routes.warranties.detail, {
                  warrantyId: warranty.id,
                }),
              },
              {
                label: 'Renew',
              },
            ]
          : []),
      ]}
      title={warranty ? `${warranty.policy_number} - Renew` : 'Loading...'}
    >
      {warranty ? (
        <SteppedContent
          steps={[
            {
              title: 'Product',
              content: (
                <RenewWarrantyProduct
                  onSubmit={(tariff: ProductTariff) => {
                    setTariff(tariff)
                    setCurrentStep(1)
                  }}
                />
              ),
            },
            {
              title: 'Vehicle',
              content: (
                <FormWrapper
                  onSubmit={(input: WarrantyVehicleFields) => {
                    setVehicle(input)
                    setCurrentStep(2)
                  }}
                >
                  {(props) => (
                    <Flex {...props} direction="column">
                      <FormStep
                        isLoading={renewing}
                        onBack={() => setCurrentStep(0)}
                        renderForm={() => (
                          <RenewWarrantyVehicle values={vehicle} />
                        )}
                      />
                    </Flex>
                  )}
                </FormWrapper>
              ),
            },
            {
              title: 'Customer',
              content: (
                <FormWrapper
                  onSubmit={(input: WarrantyCustomerFields) => {
                    setCustomer(input)
                    setCurrentStep(3)
                  }}
                >
                  {(props) => (
                    <Flex {...props} direction="column">
                      <FormStep
                        isLoading={renewing}
                        onBack={() => setCurrentStep(1)}
                        renderForm={() => (
                          <RenewWarrantyCustomer values={customer} />
                        )}
                      />
                    </Flex>
                  )}
                </FormWrapper>
              ),
            },
            {
              title: 'Address',
              content: (
                <FormWrapper onSubmit={handleRenew}>
                  {(props) => (
                    <Flex {...props} direction="column">
                      <FormStep
                        isLoading={renewing}
                        nextLabel="Renew"
                        onBack={() => setCurrentStep(2)}
                        renderForm={() => (
                          <RenewWarrantyAddress values={address} />
                        )}
                      />
                    </Flex>
                  )}
                </FormWrapper>
              ),
            },
          ]}
          currentStep={currentStep}
        />
      ) : null}
    </AdminTemplate>
  )
}

export default RenewWarrantyPage
