import { useCallback, useEffect, useState } from 'react'
import { OnChangeFn, SortingState } from '@tanstack/react-table'
import { useQueryParams } from './queryParams'

export const useTableSort = (
  defaultSort?: SortingState,
): {
  sorting: SortingState
  onSortingChange: OnChangeFn<SortingState>
} => {
  const [sorting, setSorting] = useState<SortingState>(defaultSort ?? [])
  const { params, updateParams } = useQueryParams()

  useEffect(() => {
    if (params) {
      if (params.sort) {
        const fields = params.sort.split(',')
        setSorting(
          fields.map((field: string) => {
            const desc = field.startsWith('-')
            return {
              desc,
              id: desc ? field.substring(1) : field,
            }
          }),
        )
      } else {
        setSorting([])
      }
    }
  }, [params])

  const onSortingChange = useCallback(
    (updater) => {
      const state: SortingState = updater()
      updateParams({
        sort: state
          .map((sort) => `${sort.desc ? '-' : ''}${sort.id}`)
          .join(','),
      })
    },
    [updateParams],
  )

  return { sorting, onSortingChange }
}
