import qs from 'qs'
import { API } from './API'
import type {
  APIException,
  AssignedResponse,
  PaginatedResponse,
  QueryParams,
  SingularResponse,
  User,
  WarrantiesMeta,
  WarrantiesMetaResponse,
  Warranty,
  WarrantyResponse,
  WarrantyUpdate,
} from '../types'
import {
  mapPaginatedResponse,
  mapTableQuery,
  mapWarrantiesMeta,
  mapWarranty,
  mapWarrantyUpdate,
} from '../mappers'

export class WarrantyService {
  public async list(
    params: QueryParams,
  ): Promise<PaginatedResponse<Warranty, WarrantiesMeta>> {
    return (await API.getConnection())
      .get('warranties', {
        params: { ...mapTableQuery(params) },
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then(
        (response: {
          data: PaginatedResponse<WarrantyResponse, WarrantiesMetaResponse>
        }) =>
          mapPaginatedResponse<
            WarrantyResponse,
            Warranty,
            WarrantiesMetaResponse,
            WarrantiesMeta
          >(response.data, mapWarranty, mapWarrantiesMeta),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async single(id: number, archive = false): Promise<Warranty> {
    return (await API.getConnection())
      .get(`warranties/${id}`, {
        params: {
          include: 'notes,tasks,renewals,renewed',
          ...(archive ? { filter: { archive: 'only' } } : {}),
        },
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then((response: SingularResponse<WarrantyResponse>) =>
        mapWarranty(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async create(data: WarrantyUpdate): Promise<Warranty> {
    return (await API.getConnection())
      .post('warranties', mapWarrantyUpdate(data))
      .then((response: SingularResponse<WarrantyResponse>) =>
        mapWarranty(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async assign(
    warranties: Warranty[],
    user: User | null,
  ): Promise<number> {
    return (await API.getConnection())
      .put(`warranties/assign`, {
        warranties: warranties.map(({ id }) => `${id}`),
        user: user?.id ?? null,
      })
      .then((response: { data: AssignedResponse }) => response.data.assigned)
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async update(id: number, data: WarrantyUpdate): Promise<Warranty> {
    return (await API.getConnection())
      .put(`warranties/${id}`, mapWarrantyUpdate(data))
      .then((response: SingularResponse<WarrantyResponse>) =>
        mapWarranty(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async startRenewal(
    id: number,
    data: WarrantyUpdate,
  ): Promise<Warranty> {
    return (await API.getConnection())
      .post(`warranties/${id}/renew`, mapWarrantyUpdate(data))
      .then((response: SingularResponse<WarrantyResponse>) =>
        mapWarranty(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async parseImport(file: File): Promise<any[]> {
    const body = new FormData()
    if (file) {
      body.append('data', file)
    }
    return (await API.getConnection())
      .post('warranties/import/parse', body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: { data: { rows: any[] } }) => response.data.rows)
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async storeImport(data: any[]): Promise<number> {
    return (await API.getConnection())
      .post('warranties/import/store', { import: data })
      .then(
        (response: { data: { imported: number } }) => response.data.imported,
      )
      .catch((error: APIException | null) => API.handleError(error))
  }
}
