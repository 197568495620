import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Alert, AlertIcon, Button, Flex, SimpleGrid } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import { Morph, Task } from '../../../../types'
import Card from '../../../../horizon/components/card/Card'
import GridForm from '../forms/GridForm'
import {
  updateTask,
  updateTaskAdmin,
  UpdateTaskAdminFields,
  UpdateTaskFields,
} from '../../../../forms'
import {
  MANAGEMENT,
  TASK_TYPES,
  TELESALES,
  userCanAccess,
} from '../../../../utils'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { useUsersByRole } from '../../../../hooks'

const TaskDetails: React.FunctionComponent<{
  morph: Morph
  task?: Task
  onSaved: (task: Task) => void
}> = ({ morph, task, onSaved }) => {
  const user = useSelector(getUser)
  const isAdmin = useMemo(() => userCanAccess(user, MANAGEMENT), [user])
  const services: AvailableServices = useContext(ServiceContext)
  const form = useForm<UpdateTaskFields | UpdateTaskAdminFields>()
  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)
  const { users } = useUsersByRole(TELESALES)

  useEffect(() => {
    form.setValue('type', (task?.type ?? TASK_TYPES[0]).slug)
    form.setValue('description', task?.description ?? '')
    form.setValue('dueDate', task?.due_at)
    if (isAdmin) {
      form.setValue('assignedTo', task?.assigned_to?.id ?? null)
    }
  }, [task, isAdmin, form])

  const onSubmit = useCallback(
    (input: UpdateTaskFields | UpdateTaskAdminFields) => {
      setError('')
      setSaving(true)
      const done = (updated: Task) => {
        setSaving(false)
        onSaved(updated)
      }
      const error = () => {
        setSaving(false)
        setError('Sorry, there was a problem saving the task')
      }
      if (task) {
        services.task
          .update(task.id, { morph, ...input })
          .then(done)
          .catch(error)
      } else {
        services.task
          .create({ morph, ...input })
          .then(done)
          .catch(error)
      }
    },
    [morph, task, services, onSaved],
  )

  return (
    <FormProvider {...form}>
      <Flex
        direction="column"
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate={true}
        gap="1rem"
      >
        <Card display="flex" flexDirection="column">
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1rem">
            {error ? (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            ) : null}
            <GridForm
              config={isAdmin ? updateTaskAdmin : updateTask}
              extras={{
                type: {
                  options: TASK_TYPES.map((type) => ({
                    value: type.slug,
                    title: type.name,
                  })),
                },
                assignedTo: {
                  options: [
                    { value: '', title: 'Unassigned' },
                    ...users.map((user) => ({
                      value: user.id,
                      title: user.full_name,
                    })),
                  ],
                },
              }}
              isDisabled={saving}
            />
          </SimpleGrid>
        </Card>
        <Flex justifyContent="flex-end">
          <Button colorScheme="brand" type="submit" isDisabled={saving}>
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  )
}

export default TaskDetails
