import { WarrantyImportField, WarrantyStatus } from '../types'
import { DATABASE_DATE_FORMAT, formatOptional, tryParseDate } from './dates'
import {
  MdBookmark,
  MdClose,
  MdPriorityHigh,
  MdQuestionMark,
} from 'react-icons/md'

export const WARRANTY_IMPORT_FIELDS: WarrantyImportField[] = [
  {
    name: 'number',
    title: 'Warranty Number',
    matches: ['Num', 'Warranty Number', 'ID Number'],
    required: true,
  },
  {
    name: 'garage_name',
    title: 'Garage',
    matches: ['Garage Purchased From'],
    required: true,
  },
  {
    name: 'garage_code',
    title: 'Garage Code',
    matches: ['Garage Code'],
    required: true,
  },
  {
    name: 'title',
    title: 'Customer Title',
    matches: ['Title'],
    required: true,
  },
  {
    name: 'first_name',
    title: 'Customer First Name',
    matches: ['Initial', 'Initials'],
    required: true,
  },
  {
    name: 'last_name',
    title: 'Customer Last Name',
    matches: ['Surname'],
    required: true,
  },
  {
    name: 'address_1',
    title: 'Address Line 1',
    matches: ['Address 1'],
    required: true,
  },
  { name: 'address_2', title: 'Address Line 2', matches: ['Address 2'] },
  { name: 'address_3', title: 'Address Line 3', matches: ['Address 3'] },
  { name: 'address_4', title: 'Address Line 4', matches: ['Address 4'] },
  {
    name: 'postcode',
    title: 'Postcode',
    matches: ['P.code', 'Postcode'],
    required: true,
  },
  {
    name: 'phone',
    title: 'Telephone Number',
    matches: ['Tel', 'Telephone', 'Phone'],
  },
  { name: 'mobile', title: 'Mobile Number', matches: ['Mobile'] },
  { name: 'email', title: 'Email Address', matches: ['Email'] },
  {
    name: 'policy_number',
    title: 'Policy Number',
    matches: ['Policy', 'Policy Number'],
    required: true,
  },
  {
    name: 'expires_on',
    title: 'Policy Expiry Date',
    matches: ['Exp date', 'Expiry Date'],
    required: true,
    transform: (value: string) =>
      formatOptional(tryParseDate(value), DATABASE_DATE_FORMAT),
  },
  {
    name: 'length',
    title: 'Policy Length',
    matches: ['Month', 'Length', 'Duration'],
    required: true,
  },
  {
    name: 'type',
    title: 'Product Type',
    matches: ['Type', 'Product Type', 'Policy Type'],
    required: true,
  },
  {
    name: 'claim_limit',
    title: 'Claim Limit',
    matches: ['Claim Limit', 'X10'],
  },
  {
    name: 'vehicle_registration',
    title: 'Vehicle Registration',
    matches: ['Reg', 'Registration', 'Car Registration'],
    required: true,
  },
  { name: 'make', title: 'Vehicle Make', matches: ['Make'], required: true },
  { name: 'model', title: 'Vehicle Model', matches: ['Model'], required: true },
  {
    name: 'mileage',
    title: 'Vehicle Mileage',
    matches: ['Mileage'],
    required: true,
  },
  {
    name: 'price',
    title: 'Vehicle Purchase Price',
    matches: ['Price', 'Purchase Price'],
  },
  {
    name: 'purchased_on',
    title: 'Vehicle Purchase Date',
    matches: ['Pur. date', 'Purchased', 'Policy Start Date'],
    required: true,
    transform: (value: string) =>
      formatOptional(tryParseDate(value), DATABASE_DATE_FORMAT),
  },
]

export const attemptImportFieldMatch = (
  title: string,
): WarrantyImportField | undefined => {
  return WARRANTY_IMPORT_FIELDS.find(
    (field) =>
      field.matches.filter(
        (value) => value.toLowerCase() === title.toLowerCase(),
      ).length > 0,
  )
}

export const WARRANTY_STATUSES: WarrantyStatus[] = [
  {
    title: 'Not Contacted',
    key: 'notContacted',
    colour: '#a6a6a6',
    contrastColour: 'white',
    manual: true,
    fixed: false,
    reason: false,
    summarised: true,
    icon: MdQuestionMark,
  },
  {
    title: 'Lead',
    key: 'lead',
    colour: '#edbb5c',
    contrastColour: 'gray.900',
    manual: true,
    fixed: false,
    reason: false,
    summarised: true,
    icon: MdBookmark,
  },
  {
    title: 'No Response',
    key: 'noResponse',
    colour: '#fd8b43',
    contrastColour: 'white',
    manual: true,
    fixed: false,
    reason: false,
    summarised: true,
    icon: MdPriorityHigh,
  },
  {
    title: 'Customer to Contact',
    key: 'customerToContact',
    colour: '#2bb9ed',
    contrastColour: 'white',
    manual: true,
    fixed: false,
    reason: false,
    summarised: false,
  },
  {
    title: 'Processed Sale',
    key: 'processedSale',
    colour: '#20bc1b',
    contrastColour: 'white',
    manual: false,
    fixed: true,
    reason: false,
    summarised: false,
  },
  {
    title: 'Reject',
    key: 'reject',
    colour: '#ee0000',
    contrastColour: 'white',
    manual: true,
    fixed: false,
    reason: true,
    summarised: true,
    icon: MdClose,
  },
  {
    title: 'Pending Renewal',
    key: 'pendingRenewal',
    colour: '#c92bed',
    contrastColour: 'white',
    manual: false,
    fixed: true,
    reason: false,
    summarised: false,
  },
]

export const WARRANTY_REJECTION_REASONS: string[] = [
  'BIG Multiple',
  'Cannot Afford',
  'Claims Issue',
  'Do not want',
  'Price',
  'Level of Cover',
  'Claim Limit',
  'Vehicle Sold',
]
