import * as React from 'react'
import { useMemo } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { MANAGEMENT, userCanAccess } from '../../../../utils'
import ManagementDashboard from '../../organisms/dashboard/ManagementDashboard'
import AgentDashboard from '../../organisms/dashboard/AgentDashboard'

const Dashboard: React.FunctionComponent = () => {
  const user = useSelector(getUser)
  const isManagement = useMemo(() => userCanAccess(user, MANAGEMENT), [user])

  return (
    <AdminTemplate
      breadcrumbs={[{ label: 'Warranties 2000' }]}
      title={
        isManagement
          ? 'Telesales Admin Dashboard'
          : `Welcome back ${user.first_name}`
      }
    >
      {isManagement ? <ManagementDashboard /> : <AgentDashboard />}
    </AdminTemplate>
  )
}

export default Dashboard
