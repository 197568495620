import { FormConfig, FormFieldType } from '../types'

export type WarrantyAddressFields = {
  line1: string
  line2: string
  line3: string
  line4: string
  postcode: string
}

export const warrantyAddress: FormConfig = {
  columns: 2,
  fields: [
    {
      name: 'line1',
      label: 'Line 1',
      requiredError: "Please enter the first line of the customer's address",
      type: FormFieldType.Text,
      colSpan: 2,
    },
    {
      name: 'line2',
      label: 'Line 2',
      type: FormFieldType.Text,
      colSpan: 2,
    },
    {
      name: 'line3',
      label: 'Line 3',
      type: FormFieldType.Text,
      colSpan: 2,
    },
    {
      name: 'line4',
      label: 'Line 4',
      type: FormFieldType.Text,
      colSpan: 2,
    },
    {
      name: 'postcode',
      label: 'Postcode',
      requiredError: "Please enter the customer's postcode",
      type: FormFieldType.Text,
    },
  ],
}

export type WarrantyCustomerFields = {
  title: string
  firstName: string
  lastName: string
  phone: string
  mobile: string
  email: string
}

export const warrantyCustomer: FormConfig = {
  columns: 2,
  fields: [
    {
      name: 'title',
      label: 'Title',
      requiredError: 'Please enter a title',
      type: FormFieldType.Text,
    },
    {
      type: FormFieldType.Spacer,
    },
    {
      name: 'firstName',
      label: 'First Name',
      requiredError: 'Please enter a first name',
      type: FormFieldType.Text,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      requiredError: 'Please enter a last name',
      type: FormFieldType.Text,
    },
    {
      name: 'phone',
      label: 'Telephone Number',
      type: FormFieldType.Text,
    },
    {
      name: 'mobile',
      label: 'Mobile Number',
      type: FormFieldType.Text,
    },
    {
      name: 'email',
      label: 'Email Address',
      type: FormFieldType.Text,
    },
  ],
}

export type WarrantyVehicleFields = {
  registration: string
  make: string
  model: string
  mileage: string
  price: string
}

export const warrantyVehicle: FormConfig = {
  columns: 2,
  fields: [
    {
      name: 'registration',
      label: 'Registration',
      requiredError: 'Please enter the vehicle registration',
      type: FormFieldType.Text,
    },
    {
      type: FormFieldType.Spacer,
    },
    {
      name: 'make',
      label: 'Make',
      requiredError: 'Please enter the vehicle make',
      type: FormFieldType.Text,
    },
    {
      name: 'model',
      label: 'Model',
      requiredError: 'Please enter the vehicle model',
      type: FormFieldType.Text,
    },
    {
      name: 'mileage',
      label: 'Mileage',
      type: FormFieldType.Text,
    },
    {
      name: 'price',
      label: 'Price',
      type: FormFieldType.Text,
    },
  ],
}

export type WarrantyInfoFields = {
  policyNumber: string
  type: string
  purchaseDate: string
  length: string
  claimLimit: string
  expiryDate: string
}

export const warrantyInfo: FormConfig = {
  columns: 1,
  fields: [
    {
      name: 'policyNumber',
      label: 'Product Number',
      type: FormFieldType.Text,
    },
    {
      name: 'type',
      label: 'Product Type',
      type: FormFieldType.Text,
    },
    {
      name: 'purchaseDate',
      label: 'Start Date',
      type: FormFieldType.Text,
    },
    {
      name: 'length',
      label: 'Duration',
      type: FormFieldType.Text,
    },
    {
      name: 'claimLimit',
      label: 'Claim Limit',
      type: FormFieldType.Text,
    },
    {
      name: 'expiryDate',
      label: 'Expiry Date',
      type: FormFieldType.Text,
    },
  ],
}

export type WarrantyProductFields = {
  product: number
  duration: number
  claimLimit: number
}

export const warrantyProduct: FormConfig = {
  columns: 2,
  fields: [
    {
      name: 'product',
      label: 'Product',
      requiredError: 'Please select a product',
      type: FormFieldType.Select,
    },
    {
      name: 'duration',
      label: 'Duration',
      requiredError: 'Please select a duration',
      type: FormFieldType.Select,
    },
    {
      type: FormFieldType.Spacer,
    },
    {
      name: 'claimLimit',
      label: 'Claim Limit',
      requiredError: 'Please select a claim limit',
      type: FormFieldType.Select,
    },
    {
      type: FormFieldType.Spacer,
    },
    {
      name: 'selected',
      type: FormFieldType.Spacer,
      colSpan: 2,
    },
  ],
}
