import React, { PropsWithChildren, useCallback } from 'react'
import { ProductTariff } from '../../../../types'
import { Box, Button, useColorModeValue } from '@chakra-ui/react'
import { formatCurrency, formatFurtherPayments } from '../../../../utils'

const Header: React.FunctionComponent<
  PropsWithChildren<{ minWidth?: string; grow?: boolean }>
> = ({ minWidth, grow, children }) => {
  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const borderColour = useColorModeValue('secondaryGray.400', 'white.400')
  return (
    <Box
      as="th"
      textColor={textPrimary}
      textAlign="left"
      fontSize="md"
      borderColor={borderColour}
      borderWidth={1}
      padding="0.5rem"
      width={grow ? '100%' : minWidth ?? undefined}
    >
      {children}
    </Box>
  )
}

const Cell: React.FunctionComponent<
  PropsWithChildren<{ centre?: boolean; highlight: boolean }>
> = ({ centre, highlight, children }) => {
  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const borderColour = useColorModeValue('secondaryGray.400', 'white.400')
  const highlightColour = useColorModeValue('secondaryGray.300', 'white.300')
  return (
    <Box
      as="td"
      textColor={textPrimary}
      fontSize="sm"
      backgroundColor={highlight ? highlightColour : undefined}
      borderColor={borderColour}
      borderWidth={1}
      padding="0.5rem"
      textAlign={centre ? 'center' : 'left'}
      whiteSpace="nowrap"
    >
      {children}
    </Box>
  )
}

const SelectTariffTable: React.FunctionComponent<{
  tariffs: ProductTariff[]
  selected: ProductTariff | undefined
  onSelect: (tariff: ProductTariff | undefined) => void
}> = ({ tariffs, selected, onSelect }) => {
  const handleSelect = useCallback(
    (tariff: ProductTariff) => {
      onSelect(tariff.id === selected?.id ? undefined : tariff)
    },
    [selected, onSelect],
  )

  const borderColour = useColorModeValue('secondaryGray.400', 'white.400')
  return tariffs.length > 0 ? (
    <Box as="table" width="100%" borderColor={borderColour} borderWidth={1}>
      <thead>
        <tr>
          <Header>Plan</Header>
          <Header>Deposit</Header>
          <Header grow>Further Payments</Header>
          <Header minWidth="160px" />
        </tr>
      </thead>
      <tbody>
        {tariffs.map((tariff, index) => {
          const isSelected = tariff.id === selected?.id
          return (
            <tr key={index}>
              <Cell highlight={isSelected}>{tariff.plan?.name ?? '-'}</Cell>
              <Cell highlight={isSelected}>
                {tariff.initial ? formatCurrency(tariff.initial) : '-'}
              </Cell>
              <Cell highlight={isSelected}>
                {formatFurtherPayments(tariff)}
              </Cell>
              <Cell centre highlight={isSelected}>
                <Button
                  variant={isSelected ? 'brand' : undefined}
                  onClick={() => handleSelect(tariff)}
                >
                  {isSelected ? 'Selected' : 'Select'}
                </Button>
              </Cell>
            </tr>
          )
        })}
      </tbody>
    </Box>
  ) : null
}

export default SelectTariffTable
