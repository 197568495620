import {
  Product,
  ProductResponse,
  ProductTariff,
  ProductTariffResponse,
  ProductTariffUpdate,
  ProductTariffUpdateData,
  ProductUpdate,
  ProductUpdateData,
} from '../types'
import { DATABASE_DATETIME_FORMAT } from '../utils'
import { format } from 'date-fns'

export const mapProduct = (data: ProductResponse): Product => {
  return {
    ...data,
    tariffs:
      data.tariffs?.length > 0 ? data.tariffs.map(mapProductTariff) : undefined,
    paused_at: data.paused_at ? new Date(data.paused_at) : null,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }
}

export const mapProductUpdate = (data: ProductUpdate): ProductUpdateData => {
  const { paused, ...attrs } = data
  return {
    ...attrs,
    paused_at:
      paused === undefined
        ? undefined
        : !paused
          ? null
          : format(new Date(), DATABASE_DATETIME_FORMAT),
  }
}

export const mapProductTariff = (
  data: ProductTariffResponse,
): ProductTariff => {
  const { created_at, updated_at, product, ...attrs } = data
  return {
    ...attrs,
    product: product ? mapProduct(product) : undefined,
    paused_at: data.paused_at ? new Date(data.paused_at) : null,
    created_at: new Date(created_at),
    updated_at: new Date(updated_at),
  }
}

export const mapProductTariffUpdate = (
  data: ProductTariffUpdate,
): ProductTariffUpdateData => {
  const { claimLimit, grossPrice, netPrice, adminFee, paused, ...attrs } = data
  return {
    ...attrs,
    claim_limit: claimLimit,
    gross_price: grossPrice,
    net_price: netPrice,
    admin_fee: adminFee,
    paused_at:
      paused === undefined
        ? undefined
        : !paused
          ? null
          : format(new Date(), DATABASE_DATETIME_FORMAT),
  }
}
