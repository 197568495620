import React from 'react'
import WarrantyAdminSummary from '../warranties/WarrantyAdminSummary'
import { Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react'
import { useTaskTableData, useWarrantyTableData } from '../../../../hooks'
import WarrantiesTable from '../warranties/WarrantiesTable'
import Card from '../../../../horizon/components/card/Card'
import { User } from '../../../../types'
import TasksTable from '../tasks/TasksTable'

const AgentDashboard: React.FunctionComponent<{ user?: User }> = ({ user }) => {
  const warrantyQueryParamKey = 'agentDashboardWarranties'
  const taskQueryParamKey = 'agentDashboardTasks'

  const { warranties, loadingWarranties } = useWarrantyTableData(
    {
      summary: true,
      user,
    },
    true,
    warrantyQueryParamKey,
  )
  const { tasks, loadingTasks } = useTaskTableData(
    {
      user,
      incomplete: true,
    },
    true,
    taskQueryParamKey,
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Flex direction="column" marginBottom="2rem">
      <WarrantyAdminSummary
        summary={warranties?.meta?.summary}
        loading={loadingWarranties}
      />
      <SimpleGrid columns={2} gap="1rem">
        <Card padding="1rem" display="flex" flexDirection="column" gap="1rem">
          <Text
            fontSize="xl"
            color={textPrimary}
            fontWeight="bold"
            paddingX="0.5rem"
          >
            {user ? `${user.first_name}'s` : 'My'} Warranties
          </Text>
          <WarrantiesTable
            data={warranties}
            loading={loadingWarranties}
            narrow
            queryParamKey={warrantyQueryParamKey}
          />
        </Card>
        <Card padding="1rem" display="flex" flexDirection="column" gap="1rem">
          <Text
            fontSize="xl"
            color={textPrimary}
            fontWeight="bold"
            paddingX="0.5rem"
          >
            {user ? `${user.first_name}'s` : 'My'} Tasks
          </Text>
          <TasksTable
            tasks={tasks?.data}
            meta={tasks?.meta}
            loading={loadingTasks}
            narrow
            canManage={false}
            queryParamKey={taskQueryParamKey}
          />
        </Card>
      </SimpleGrid>
    </Flex>
  )
}

export default AgentDashboard
