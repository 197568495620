import * as React from 'react'
import { Text, useColorModeValue } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

const DataTableCell: React.FunctionComponent<
  PropsWithChildren<{ faded?: boolean }>
> = ({ faded, children }) => {
  const textColour = useColorModeValue('navy.700', 'white')
  return (
    <Text
      color={textColour}
      fontSize="md"
      fontWeight="500"
      opacity={faded ? 0.5 : 1}
    >
      {children}
    </Text>
  )
}

export default DataTableCell
