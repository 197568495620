import React, { useEffect, useMemo } from 'react'
import { warrantyProduct, WarrantyProductFields } from '../../../../forms'
import GridForm from '../forms/GridForm'
import { useProducts } from '../../../../hooks'
import { useFormContext } from 'react-hook-form'
import { Product, ProductTariff } from '../../../../types'
import { formatCurrency } from '../../../../utils'
import SelectTariffTable from '../../molecules/products/SelectTariffTable'

const WarrantyProductSelection: React.FunctionComponent<{
  selectedTariff: ProductTariff | undefined
  onTariffSelected: (tariff: ProductTariff | undefined) => void
}> = ({ selectedTariff, onTariffSelected }) => {
  const { products: allProducts, loading: loadingProducts } = useProducts()
  const products: Product[] = useMemo<Product[]>(
    () => allProducts.filter((product: Product) => !product.paused_at),
    [allProducts],
  )
  const { watch, setValue } = useFormContext<WarrantyProductFields>()

  const productId = watch('product')
  useEffect(() => setValue('duration', 0), [setValue, productId])
  const product = useMemo<Product | undefined>(
    () => products.find(({ id }) => `${id}` === `${productId}`),
    [products, productId],
  )
  const allTariffs: ProductTariff[] = useMemo(() => {
    if (product) {
      return product.tariffs.filter((tariff) => !tariff.paused_at)
    }
    return []
  }, [product])
  const durations = useMemo<
    { title: string; value: number; tariffs: ProductTariff[] }[]
  >(() => {
    if (allTariffs.length) {
      const grouped = [...new Set(allTariffs.map(({ duration }) => duration))]
      return grouped.map((duration) => ({
        title: `${duration} Months`,
        value: duration,
        tariffs: allTariffs.filter((tariff) => tariff.duration === duration),
      }))
    }
    return []
  }, [allTariffs])
  const duration = watch('duration')
  const durationTariffs = useMemo(
    () =>
      duration
        ? (allTariffs ?? []).filter(
            (tariff) => `${tariff.duration}` === `${duration}`,
          )
        : [],
    [allTariffs, duration],
  )

  const claimLimits = useMemo<
    { title: string; value: number; tariffs: ProductTariff[] }[]
  >(() => {
    const grouped = [
      ...new Set(durationTariffs.map(({ claim_limit }) => claim_limit)),
    ]
    return grouped.map((limit) => ({
      title: formatCurrency(limit),
      value: limit,
      tariffs: durationTariffs.filter((tariff) => tariff.claim_limit === limit),
    }))
  }, [durationTariffs])
  const limit = watch('claimLimit')
  const limitTariffs = useMemo(
    () =>
      limit
        ? durationTariffs.filter(
            (tariff) => `${tariff.claim_limit}` === `${limit}`,
          )
        : [],
    [durationTariffs, limit],
  )

  return (
    <GridForm
      config={warrantyProduct}
      extras={useMemo(
        () => ({
          product: {
            options: [
              { value: 0, title: '------' },
              ...products.map((product) => ({
                value: product.id,
                title: product.name,
              })),
            ],
          },
          duration: {
            isHidden: durations.length < 1,
            options: [{ value: 0, title: '------' }, ...durations],
          },
          claimLimit: {
            isHidden: claimLimits.length < 1,
            options: [{ value: 0, title: '------' }, ...claimLimits],
          },
          selected: {
            isHidden: limitTariffs.length < 1,
            content: (
              <SelectTariffTable
                tariffs={limitTariffs}
                selected={selectedTariff}
                onSelect={(tariff: ProductTariff) => {
                  onTariffSelected(tariff)
                }}
              />
            ),
          },
        }),
        [
          selectedTariff,
          onTariffSelected,
          products,
          durations,
          claimLimits,
          limitTariffs,
        ],
      )}
      isDisabled={loadingProducts}
    />
  )
}

export default WarrantyProductSelection
