import { InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseField, FieldProps } from './BaseField'
import { getFieldOptions } from '../../../../utils'
import DatePicker from './DatePicker'

export function DateField<T extends object>(
  props: FieldProps & { includeTime?: boolean },
) {
  const { field, isDisabled, includeTime } = props
  const { register, watch, setValue } = useFormContext<T>()

  useEffect(() => {
    register(field.name as any, getFieldOptions(field))
  }, [register, field])

  const value = watch(field.name as any)

  return (
    <BaseField {...props}>
      <InputGroup className="blueprint">
        {field.leftAddon ? (
          <InputLeftAddon
            height="auto"
            borderBottomLeftRadius="1rem"
            borderTopLeftRadius="1rem"
            opacity={isDisabled ? '0.5' : '1'}
          >
            {field.leftAddon}
          </InputLeftAddon>
        ) : null}
        <DatePicker
          value={(value as Date) ?? null}
          onChange={(value) => setValue(field.name as any, value as any)}
          includeTime={includeTime}
        />
        {field.rightAddon ? (
          <InputRightAddon
            height="auto"
            borderBottomRightRadius="1rem"
            borderTopRightRadius="1rem"
            opacity={isDisabled ? '0.5' : '1'}
          >
            {field.rightAddon}
          </InputRightAddon>
        ) : null}
      </InputGroup>
    </BaseField>
  )
}
