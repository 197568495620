import * as React from 'react'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { HeaderContext, RowData } from '@tanstack/react-table'
import { TableColumnOptions } from '../../../../utils'
import { useTableSort } from '../../../../hooks'
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'

interface DataTableProps<
  TRow extends RowData,
  TValue extends number | string | unknown,
> {
  context: HeaderContext<TRow, TValue>
  options: TableColumnOptions
}

function DataTableHeader<
  TRow extends RowData,
  TValue extends number | string | unknown,
>({
  context,
  options = {},
  children,
}: PropsWithChildren<DataTableProps<TRow, TValue>>) {
  const { onSortingChange } = useTableSort()

  const canSort = useMemo<boolean>(() => options.sorting ?? false, [options])
  const currentSort = useMemo<false | 'asc' | 'desc'>(
    () => context.column.getIsSorted(),
    [context],
  )

  const handleClick = useCallback(() => {
    if (canSort) {
      switch (currentSort) {
        case 'asc':
          onSortingChange(() => [{ id: context.column.id, desc: true }])
          break
        case 'desc':
          onSortingChange(() => [])
          break
        case false:
        default:
          onSortingChange(() => [{ id: context.column.id, desc: false }])
          break
      }
    }
  }, [context, onSortingChange, canSort, currentSort])

  return (
    <Text
      align="left"
      alignItems="center"
      color="gray.400"
      cursor={canSort ? 'pointer' : 'auto'}
      display="flex"
      fontSize={{ sm: '10px', lg: '12px' }}
      gap="0.5rem"
      justifyContent="flex-start"
      onClick={handleClick}
    >
      {children}{' '}
      {currentSort === 'desc' ? (
        <MdArrowUpward />
      ) : currentSort === 'asc' ? (
        <MdArrowDownward />
      ) : null}
    </Text>
  )
}

export default DataTableHeader
