import { API } from './API'
import type {
  APIException,
  NewNote,
  Note,
  NoteResponse,
  SingularResponse,
} from '../types'
import { mapNewNote, mapNote } from '../mappers'

export class NoteService {
  public async create(data: NewNote): Promise<Note> {
    return (await API.getConnection())
      .post('notes', mapNewNote(data))
      .then((response: SingularResponse<NoteResponse>) =>
        mapNote(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }
}
