import React from 'react'
import { Button, Flex } from '@chakra-ui/react'

const FormStep: React.FunctionComponent<{
  renderForm: (disabled: boolean) => React.ReactElement
  nextLabel?: string
  canSubmit?: boolean
  onBack?: () => void
  isLoading?: boolean
}> = ({
  renderForm,
  nextLabel = 'Next',
  canSubmit = true,
  onBack,
  isLoading = false,
}) => {
  return (
    <Flex direction="column">
      {renderForm(isLoading)}
      <Flex direction="row" gap="10px" marginTop="2rem">
        {onBack ? (
          <Button
            variant="outline"
            onClick={() => onBack()}
            minW="183px"
            fontSize="sm"
            fontWeight="500"
            isDisabled={isLoading}
          >
            Back
          </Button>
        ) : null}
        <Flex flex="1" />
        {canSubmit ? (
          <Button
            variant="brand"
            type="submit"
            minW="183px"
            fontSize="sm"
            fontWeight="500"
            isDisabled={isLoading}
          >
            {nextLabel}
          </Button>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default FormStep
