import { format, isBefore, parse, startOfDay } from 'date-fns'

export const DATABASE_DATE_FORMAT = 'yyyy-MM-dd'
export const DATABASE_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const DATE_FORMAT = 'dd/MM/yyyy'
export const TIME_FORMAT = 'HH:mm'
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export const SHORT_DATE_FORMAT = 'd/M/yyyy'

export const formatMonths = (months: number) =>
  `${months} Month${months === 1 ? '' : 's'}`

export const formatOptional = (
  date: Date | undefined,
  formatString: string,
): string | undefined => {
  return date ? format(date, formatString) : undefined
}

export const tryParseDate = (date: string): Date | undefined => {
  let parsed: Date | undefined
  try {
    parsed = parse(date, DATABASE_DATE_FORMAT, startOfDay(new Date()))
  } catch (ex) {
  } finally {
    try {
      parsed = parse(date, DATE_FORMAT, startOfDay(new Date()))
    } catch (ex) {
    } finally {
      try {
        parsed = parse(date, SHORT_DATE_FORMAT, startOfDay(new Date()))
      } catch (ex) {}
    }
  }
  return parsed
}

export const sortByDate = (
  list: any[],
  field: string,
  desc: boolean = false,
): any[] => {
  return list.sort((a: any, b: any) =>
    isBefore(a[field], b[field]) ? (desc ? 1 : -1) : desc ? -1 : 1,
  )
}
