import * as React from 'react'
import { useMemo, useState } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '../../molecules/shared/DataTable'
import { Morph, Note } from '../../../../types'
import { accessorColumn, DATETIME_FORMAT } from '../../../../utils'
import { MdAdd } from 'react-icons/md'
import TableButtons from '../../molecules/shared/TableButtons'
import { format } from 'date-fns'
import NoteModal from './NoteModal'

type NoteRow = {
  body: string
  user: string
  created: string
}

const NotesTable: React.FunctionComponent<{
  morph: Morph
  notes?: Note[]
  onCreated?: (note: Note) => void
}> = ({ morph, notes, onCreated }) => {
  const [showForm, setShowForm] = useState(false)

  const rows = useMemo<NoteRow[]>(
    () =>
      (notes ?? []).map((note: Note) => ({
        body: note.body,
        user: note.user?.full_name ?? '-',
        created: format(note.created_at, DATETIME_FORMAT),
      })),
    [notes],
  )

  const columnHelper = createColumnHelper<NoteRow>()
  const columns = [
    accessorColumn<NoteRow, string>(columnHelper, 'body', 'Message'),
    accessorColumn<NoteRow, string>(columnHelper, 'user', 'Author'),
    accessorColumn<NoteRow, string>(columnHelper, 'created', 'Date / Time'),
  ]

  return (
    <>
      <DataTable
        meta={null}
        rows={rows}
        columns={columns as any}
        loading={false}
        canSearch={false}
        actions={
          <TableButtons
            buttons={[
              {
                title: 'Add New Note',
                icon: MdAdd,
                onClick: () => {
                  setShowForm(true)
                },
              },
            ]}
          />
        }
      />
      <NoteModal
        morph={morph}
        open={showForm}
        onSaved={onCreated}
        onClose={() => setShowForm(false)}
      />
    </>
  )
}

export default NotesTable
