import * as React from 'react'
import { useState } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import WarrantiesTable from '../../organisms/warranties/WarrantiesTable'
import TableButtons from '../../molecules/shared/TableButtons'
import { MdSupervisedUserCircle, MdTune, MdUploadFile } from 'react-icons/md'
import { MANAGEMENT, userCanAccess } from '../../../../utils'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { useWarrantyTableData } from '../../../../hooks'
import WarrantyFilterModal from '../../organisms/warranties/WarrantyFilterModal'

const WarrantiesPage: React.FunctionComponent = () => {
  const currentUser = useSelector(getUser)
  const { warranties, loadingWarranties } = useWarrantyTableData({
    filters: true,
  })
  const [showFilters, setShowFilters] = useState(false)

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Warranties' },
      ]}
      title="Warranties"
    >
      <WarrantiesTable
        data={warranties}
        loading={loadingWarranties}
        actions={
          <TableButtons
            buttons={[
              ...(warranties?.meta
                ? [
                    {
                      title: 'Filter',
                      icon: MdTune,
                      onClick: () => setShowFilters(true),
                    },
                  ]
                : []),
              ...(userCanAccess(currentUser, MANAGEMENT)
                ? [
                    {
                      title: 'Assign',
                      icon: MdSupervisedUserCircle,
                      route: routes.warranties.assign,
                    },
                    {
                      title: 'Import CSV',
                      icon: MdUploadFile,
                      route: routes.warranties.import,
                    },
                  ]
                : []),
            ]}
          />
        }
      />
      {warranties?.meta ? (
        <WarrantyFilterModal
          meta={warranties.meta}
          open={showFilters}
          onClose={() => setShowFilters(false)}
        />
      ) : null}
    </AdminTemplate>
  )
}

export default WarrantiesPage
