import qs from 'qs'
import { API } from './API'
import type {
  APIException,
  PaginatedResponse,
  PaginationMeta,
  PaginationMetaResponse,
  Product,
  ProductResponse,
  ProductTariffUpdate,
  ProductUpdate,
  QueryParams,
  SingularResponse,
} from '../types'
import {
  mapPaginatedResponse,
  mapPaginationMeta,
  mapProduct,
  mapProductTariffUpdate,
  mapProductUpdate,
  mapTableQuery,
} from '../mappers'

export class ProductService {
  public async list(
    params: QueryParams,
  ): Promise<PaginatedResponse<Product, PaginationMeta>> {
    return (await API.getConnection())
      .get('products', {
        params: mapTableQuery(params),
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then(
        (response: {
          data: PaginatedResponse<ProductResponse, PaginationMetaResponse>
        }) =>
          mapPaginatedResponse<
            ProductResponse,
            Product,
            PaginationMetaResponse,
            PaginationMeta
          >(response.data, mapProduct, mapPaginationMeta),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async single(id: number, archive = false): Promise<Product> {
    return (await API.getConnection())
      .get(`products/${id}`, {
        params: archive ? { filter: { archive: 'only' } } : {},
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then((response: SingularResponse<ProductResponse>) =>
        mapProduct(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async create(data: ProductUpdate): Promise<Product> {
    return (await API.getConnection())
      .post('products', mapProductUpdate(data))
      .then((response: SingularResponse<ProductResponse>) =>
        mapProduct(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async update(id: number, data: ProductUpdate): Promise<Product> {
    return (await API.getConnection())
      .put(`products/${id}`, mapProductUpdate(data))
      .then((response: SingularResponse<ProductResponse>) =>
        mapProduct(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async uploadBooklet(id: number, file: File): Promise<Product> {
    const body = new FormData()
    body.append('file', file)
    return (await API.getConnection())
      .post(`products/${id}/booklet`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: SingularResponse<ProductResponse>) =>
        mapProduct(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async downloadBooklet(id: number): Promise<void> {
    return (await API.getConnection())
      .get(`products/${id}/booklet`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['x-filename'])
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => API.handleError(error))
  }

  public async deleteBooklet(id: number): Promise<Product> {
    return (await API.getConnection())
      .delete(`products/${id}/booklet`)
      .then((response: SingularResponse<ProductResponse>) =>
        mapProduct(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async createTariff(
    product: Product,
    data: ProductTariffUpdate,
  ): Promise<Product> {
    return (await API.getConnection())
      .post(`products/${product.id}/tariffs`, mapProductTariffUpdate(data))
      .then((response: SingularResponse<ProductResponse>) =>
        mapProduct(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async updateTariff(
    product: Product,
    id: number,
    data: ProductTariffUpdate,
  ): Promise<Product> {
    return (await API.getConnection())
      .put(`products/${product.id}/tariffs/${id}`, mapProductTariffUpdate(data))
      .then((response: SingularResponse<ProductResponse>) =>
        mapProduct(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }
}
