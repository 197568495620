import { GridItem } from '@chakra-ui/react'
import React from 'react'
import { FieldProps } from './BaseField'

export function Spacer(props: FieldProps) {
  const { field, extras } = props
  return (
    <GridItem
      {...(extras?.isHidden ? { display: 'none' } : {})}
      colSpan={field.colSpan ?? 1}
    >
      {extras?.content}
    </GridItem>
  )
}
