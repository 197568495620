import React, { useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import ConfirmationModal from './ConfirmationModal'
import { MdMenuBook } from 'react-icons/md'

const FileCard: React.FunctionComponent<{
  title?: string
  subtitle?: string
  uploaded: boolean
  fileName?: string
  onUpload: (file: File) => void
  onDownload: () => void
  onDelete: () => void
  error?: string
  loading?: boolean
  fill?: boolean
}> = ({
  title,
  subtitle,
  uploaded,
  fileName,
  onUpload,
  onDownload,
  onDelete,
  error,
  loading,
  fill,
}) => {
  const [selected, setSelected] = useState<File | undefined>(undefined)
  const [showDelete, setShowDelete] = useState(false)

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textSecondary = 'secondaryGray.600'
  const brandColour = useColorModeValue('brand.500', 'white')
  return (
    <Card display="flex" flex={fill ? 1 : 0} flexDirection="column" gap="1rem">
      {title || subtitle ? (
        <Flex direction="column">
          {title ? (
            <Text fontSize="xl" color={textPrimary} fontWeight="bold">
              {title}
            </Text>
          ) : null}
          {subtitle ? (
            <Text fontSize="md" color={textSecondary}>
              {subtitle}
            </Text>
          ) : null}
        </Flex>
      ) : null}
      {error ? (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      ) : null}
      <Flex flex={1} direction="column" position="relative">
        {!uploaded ? (
          <>
            <Box
              as="input"
              type="file"
              bottom={0}
              left={0}
              opacity={0}
              position="absolute"
              right={0}
              top={0}
              onChange={(e: any) => {
                if (
                  e.target &&
                  (e.target as any).files &&
                  (e.target as any).files[0]
                ) {
                  setSelected((e.target as any).files[0])
                }
              }}
            />
            <Flex
              alignItems="center"
              direction="column"
              gap="0.5rem"
              flex={1}
              justifyContent="center"
              padding="1rem"
              borderWidth="1px"
              borderStyle="dashed"
              borderColor={textSecondary}
              minHeight="6rem"
            >
              <Text color={textPrimary} fontSize="sm" textAlign="center">
                Click here or drag-drop a file to upload
              </Text>
              {selected ? (
                <Text
                  color={textPrimary}
                  fontSize="sm"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {selected.name}
                </Text>
              ) : null}
            </Flex>
          </>
        ) : (
          <Flex alignItems="center" justifyContent="center" flex={1} gap="1rem">
            <Icon color={brandColour} as={MdMenuBook} boxSize={10} />
            {fileName ? (
              <Text
                color={textPrimary}
                fontSize="sm"
                fontWeight="bold"
                textAlign="center"
              >
                {fileName}
              </Text>
            ) : null}
          </Flex>
        )}
      </Flex>
      <Flex direction="row" gap="10px" justifyContent="space-between">
        {!uploaded ? (
          <Button
            variant="brand"
            onClick={() => onUpload(selected)}
            minWidth="183px"
            fontSize="sm"
            fontWeight="500"
            isDisabled={loading || !selected}
          >
            Upload
          </Button>
        ) : (
          <>
            <Button
              variant="brand"
              onClick={onDownload}
              minWidth="183px"
              fontSize="sm"
              fontWeight="500"
              isDisabled={loading}
            >
              Download
            </Button>
            <Button
              variant="outline"
              onClick={() => setShowDelete(true)}
              minWidth="183px"
              fontSize="sm"
              fontWeight="500"
              isDisabled={loading}
            >
              Delete
            </Button>
          </>
        )}
      </Flex>
      <ConfirmationModal
        open={showDelete}
        title="Delete the file?"
        body="Are you sure you want to delete this file?"
        onConfirm={() => {
          setShowDelete(false)
          onDelete()
        }}
        onDeny={() => setShowDelete(false)}
        onClose={() => setShowDelete(false)}
      />
    </Card>
  )
}

export default FileCard
