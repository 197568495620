import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type {
  APIException,
  WarrantiesState,
  Warranty,
  WarrantyState,
} from '../types'
import { ServiceProvider } from '../services/ServiceProvider'
import { RootState } from './index'
import { WarrantyKey } from '../services/LoadingService'

export const loadWarranty = createAsyncThunk(
  'warranties/loadWarranty',
  async (
    id: number,
  ): Promise<{ warranty?: Warranty; exception?: APIException | null }> =>
    ServiceProvider.getServices()
      .warranty.single(id)
      .then((warranty) => ({
        warranty,
      }))
      .catch((exception: APIException | null) => ({
        exception,
      })),
)

const slice = createSlice({
  initialState: {
    warranty: {
      warranty: null,
      exception: null,
    },
  } as WarrantiesState,
  name: 'warranties',
  reducers: {
    setWarranty: (state, { payload }: PayloadAction<Warranty | null>) => {
      state.warranty.warranty = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWarranty.pending, (state, { meta: { arg: id } }) => {
      ServiceProvider.getServices().loading.toggle(
        new WarrantyKey(`${id}`),
        true,
      )
      state.warranty.warranty = null
      state.warranty.exception = null
    })
    builder.addCase(loadWarranty.rejected, (_state, { meta: { arg: id } }) => {
      ServiceProvider.getServices().loading.toggle(
        new WarrantyKey(`${id}`),
        false,
      )
    })
    builder.addCase(
      loadWarranty.fulfilled,
      (state, { payload: { warranty, exception } }) => {
        ServiceProvider.getServices().loading.toggle(
          new WarrantyKey(`${warranty.id}`),
          false,
        )
        state.warranty.warranty = warranty ?? null
        state.warranty.exception = exception ?? null
      },
    )
  },
})

export const { setWarranty } = slice.actions

export default slice.reducer

export const getWarranty = (state: RootState): WarrantyState =>
  state.warranties.warranty
