import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '../../molecules/shared/DataTable'
import type {
  Garage,
  PaginatedResponse,
  PaginationMeta,
} from '../../../../types'
import { format } from 'date-fns'
import { accessorColumn, DATE_FORMAT } from '../../../../utils'
import Actions from '../../molecules/shared/Actions'
import { MdAdd, MdVisibility } from 'react-icons/md'
import { reverse } from 'named-urls'
import routes from '../../../../routes'
import TableButtons from '../../molecules/shared/TableButtons'
import { useTableSort } from '../../../../hooks'
import { Badge } from '@chakra-ui/react'

type GarageRow = {
  id: number
  name: string
  code: string
  contact: React.ReactElement
  dateAdded: string
}

const GaragesTable: React.FunctionComponent<{
  data: PaginatedResponse<Garage, PaginationMeta>
  loading: boolean
}> = ({ data, loading }) => {
  const { sorting, onSortingChange } = useTableSort()

  const rows = useMemo<GarageRow[]>(
    () =>
      (data?.data ?? []).map((garage: Garage) => ({
        id: garage.id,
        name: garage.name,
        code: garage.code,
        contact: garage.no_contact ? (
          <Badge colorScheme="red">No Contact</Badge>
        ) : (
          <Badge colorScheme="green">OK</Badge>
        ),
        dateAdded: format(garage.created_at, DATE_FORMAT),
      })),
    [data],
  )

  const columnHelper = createColumnHelper<GarageRow>()
  const columns = [
    accessorColumn<GarageRow, string>(columnHelper, 'name', 'Name', {
      sorting: true,
    }),
    accessorColumn<GarageRow, string>(columnHelper, 'code', 'Code', {
      sorting: true,
    }),
    accessorColumn<GarageRow, string>(columnHelper, 'dateAdded', 'Date Added', {
      sorting: true,
    }),
    accessorColumn<GarageRow, string>(columnHelper, 'contact', 'Contact?', {
      sorting: true,
    }),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              {
                title: 'View',
                icon: MdVisibility,
                route: reverse(routes.garages.detail, {
                  garageId: cell.row.original.id,
                }),
              },
            ]}
          />
        ),
        [],
      ),
    }),
  ]

  return (
    <DataTable
      meta={data?.meta}
      rows={rows}
      columns={columns as any}
      loading={loading}
      sorting={sorting}
      onSortingChange={onSortingChange}
      actions={
        <TableButtons
          buttons={[
            {
              title: 'Add New Garage',
              icon: MdAdd,
              route: routes.garages.add,
            },
          ]}
        />
      }
    />
  )
}

export default GaragesTable
