import React, { useState } from 'react'
import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { WARRANTY_REJECTION_REASONS } from '../../../../utils'

const WarrantyRejectedModal: React.FunctionComponent<{
  open: boolean
  onSelect: (reason: string) => void
  onCancel: () => void
}> = ({ open, onSelect, onCancel }) => {
  const [value, setValue] = useState(WARRANTY_REJECTION_REASONS[0])
  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Please select a reason the renewal was rejected
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          as={Grid}
          templateColumns="1fr 1fr"
          gap="2rem"
          paddingBottom="2rem"
        >
          <GridItem colSpan={2}>
            <RadioGroup onChange={setValue} value={value}>
              <Stack>
                {WARRANTY_REJECTION_REASONS.map((reason, index) => (
                  <Radio key={index} value={reason} colorScheme="brand">
                    {reason}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </GridItem>
          <Button colorScheme="green" onClick={() => onSelect(value)}>
            Select
          </Button>
          <Button colorScheme="red" onClick={onCancel}>
            Cancel
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default WarrantyRejectedModal
