import React, { useCallback, useContext, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import routes from '../../../routes'
import { NavLink, useSearchParams } from 'react-router-dom'
import { RiEyeCloseLine } from 'react-icons/ri'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import AccessTemplate from '../templates/AccessTemplate'
import { ServiceContext } from '../../../components/Services'
import { User } from '../../../types'
import { setUser } from '../../../store/auth'
import { useDispatch } from 'react-redux'

type Inputs = {
  email: string
  password: string
  repeatPassword: string
}

const ResetPassword: React.FunctionComponent<{ set?: boolean }> = ({ set }) => {
  const services = useContext(ServiceContext)
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const [params] = useSearchParams()
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = useCallback(
    (input: Inputs) => {
      setError('')
      setLoading(true)
      const failed = () => {
        setLoading(false)
        setError(
          `Sorry there was a problem ${set ? '' : 're'}setting your password, please get in touch with our team`,
        )
      }
      services.auth
        .resetPassword(input.email, input.password, params.get('token'))
        .then(() => {
          services.auth
            .me()
            .then((user: User) => {
              dispatch(setUser(user))
              setLoading(false)
            })
            .catch(failed)
        })
        .catch(failed)
    },
    [set, services, dispatch, params],
  )

  const textColour = useColorModeValue('purple.900', 'white')
  const textColourSecondary = 'gray.400'
  const textColourBrand = useColorModeValue('brand.900', 'white')
  return (
    <AccessTemplate>
      <Box me="auto">
        <Heading color={textColour} fontSize="36px" mb="10px">
          {set ? 'Set' : 'Reset'} Password
        </Heading>
        <Text
          marginBottom="1rem"
          color={textColourSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Enter your email address along with a new password below
        </Text>
      </Box>
      <Flex
        marginX={{ base: 'auto', lg: '0px' }}
        flexDirection="column"
        justifyContent="center"
        paddingX={{ base: '20px', md: '0px' }}
        width="100%"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate={true}
      >
        <Flex
          direction="column"
          marginX={{ base: 'auto', lg: 'unset' }}
          marginBottom={{ base: '20px', md: 'auto' }}
          width="100%"
        >
          {error ? (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          ) : null}
          <FormControl
            marginY="0.5rem"
            isInvalid={!!errors.email?.type}
            isDisabled={loading}
          >
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColour}
              mb="8px"
            >
              Email *
            </FormLabel>
            <Input
              type="email"
              color={textColour}
              fontSize="sm"
              fontWeight="500"
              size="lg"
              {...register('email', { required: true })}
            />
            {errors.email?.type === 'required' ? (
              <FormErrorMessage>Please enter an email address</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl
            marginY="0.5rem"
            isInvalid={!!errors.password?.type}
            isDisabled={loading}
          >
            <FormLabel
              marginLeft="0.25rem"
              fontSize="sm"
              fontWeight="500"
              color={textColour}
              display="flex"
            >
              Password *
            </FormLabel>
            <InputGroup size="md">
              <Input
                color={textColour}
                fontSize="sm"
                size="lg"
                type={show ? 'text' : 'password'}
                {...register('password', { required: true })}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColourSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => (loading ? null : setShow(!show))}
                />
              </InputRightElement>
            </InputGroup>
            {errors.password?.type === 'required' ? (
              <FormErrorMessage>Please enter a new password</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl
            marginY="0.5rem"
            isInvalid={!!errors.repeatPassword?.type}
            isDisabled={loading}
          >
            <FormLabel
              marginLeft="0.25rem"
              fontSize="sm"
              fontWeight="500"
              color={textColour}
              display="flex"
            >
              Repeat Password *
            </FormLabel>
            <InputGroup size="md">
              <Input
                color={textColour}
                fontSize="sm"
                size="lg"
                type={show ? 'text' : 'password'}
                {...register('repeatPassword', {
                  required: true,
                  validate: (value, formValues) =>
                    !value || value === formValues.password,
                })}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColourSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => (loading ? null : setShow(!show))}
                />
              </InputRightElement>
            </InputGroup>
            {errors.repeatPassword?.type === 'required' ? (
              <FormErrorMessage>
                Please repeat your new password
              </FormErrorMessage>
            ) : errors.repeatPassword?.type === 'validate' ? (
              <FormErrorMessage>Your passwords must match</FormErrorMessage>
            ) : null}
            <Flex alignItems="center" justifyContent="space-between">
              <NavLink to={routes.login}>
                <Text
                  color={textColourBrand}
                  fontSize="sm"
                  fontWeight="500"
                  marginTop="1rem"
                >
                  {set ? 'Already know' : 'Remembered'} your password? Click
                  here to log-in
                </Text>
              </NavLink>
            </Flex>
          </FormControl>
          <Button
            type="submit"
            variant="brand"
            fontSize="sm"
            fontWeight="500"
            marginBottom="1rem"
            marginTop="1.5rem"
            paddingY="1.5rem"
            width="100%"
            isDisabled={loading}
          >
            {set ? 'Set' : 'Reset'} Password and Login
          </Button>
        </Flex>
      </Flex>
    </AccessTemplate>
  )
}

export default ResetPassword
