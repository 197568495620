import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { QueryParams, QueryParamsState } from '../types'
import { RootState } from './index'

const slice = createSlice({
  initialState: {
    params: {},
  } as QueryParamsState,
  name: 'queryParams',
  reducers: {
    updateQueryParams: (
      state,
      {
        payload: { key, params },
      }: PayloadAction<{ key: string; params: QueryParams }>,
    ) => {
      const updated = { ...state.params }
      updated[key] = { ...(state.params[key] ?? {}), ...params }
      state.params = updated
    },
  },
})

export const { updateQueryParams } = slice.actions

export default slice.reducer

export const getQueryParams = (
  state: RootState,
): { [key: string]: QueryParams } => state.queryParams.params
