import DataTableHeader from '../modules/admin/molecules/shared/DataTableHeader'
import DataTableCell from '../modules/admin/molecules/shared/DataTableCell'
import * as React from 'react'
import { ColumnHelper, RowData } from '@tanstack/react-table'

export type TableColumnOptions = {
  sorting?: boolean
  faded?: (row: any) => boolean
}

export function accessorColumn<
  TRow extends RowData,
  TValue extends number | string,
>(
  columnHelper: ColumnHelper<TRow>,
  id: any,
  title: string,
  options: TableColumnOptions = {},
) {
  return columnHelper.accessor(id, {
    id,
    header: (context) => (
      <DataTableHeader<TRow, TValue> context={context} options={options}>
        {title}
      </DataTableHeader>
    ),
    cell: (info) => (
      <DataTableCell
        faded={options.faded ? options.faded(info.cell.row.original) : false}
      >
        {info.getValue()}
      </DataTableCell>
    ),
    enableSorting: false,
  })
}
