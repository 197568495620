import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import SteppedContent from '../../molecules/shared/SteppedContent'
import { garageDetails, GarageDetailsFields } from '../../../../forms'
import FormWrapper from '../../molecules/shared/FormWrapper'
import FormStep from '../../molecules/shared/FormStep'
import GridForm from '../../organisms/forms/GridForm'
import { Flex, useToast } from '@chakra-ui/react'
import { ServiceContext } from '../../../../components/Services'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'

const NewGaragePage: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const toast = useToast()
  const navigate = useNavigate()
  const [saving, setSaving] = useState<boolean>(false)

  const handleSave = useCallback(
    (details: GarageDetailsFields) => {
      setSaving(true)
      services.garage
        .create(details)
        .then((garage) =>
          navigate(reverse(routes.garages.detail, { garageId: garage.id })),
        )
        .catch(() => {
          setSaving(false)
          toast({
            title: 'Problem Saving Garage',
            description:
              'Sorry there was a problem saving the garage, please check the details and try again',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    },
    [services, toast, navigate],
  )

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Garages', link: routes.garages.index },
        { label: 'Add a New Garage' },
      ]}
      title="Add a New Garage"
    >
      <SteppedContent
        currentStep={0}
        steps={[
          {
            title: 'Details',
            content: (
              <FormWrapper onSubmit={handleSave}>
                {(props) => (
                  <Flex {...props} direction="column">
                    <FormStep
                      isLoading={saving}
                      nextLabel="Submit"
                      renderForm={(disabled: boolean) => (
                        <GridForm
                          config={garageDetails}
                          isDisabled={disabled}
                        />
                      )}
                    />
                  </Flex>
                )}
              </FormWrapper>
            ),
          },
        ]}
      />
    </AdminTemplate>
  )
}

export default NewGaragePage
