import React, { useCallback, useContext, useState } from 'react'
import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { useWarranty } from '../../../../hooks'
import { ServiceContext } from '../../../../components/Services'
import { WarrantyStatus as Status } from '../../../../types'
import WarrantyStatusMenu from '../../molecules/warranties/WarrantyStatusMenu'
import WarrantyRejectedModal from '../../molecules/warranties/WarrantyRejectedModal'
import { WARRANTY_STATUSES } from '../../../../utils'
import { MdRefresh, MdVisibility } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls'
import routes from '../../../../routes'

const WarrantyStatus: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const { warranty, onUpdate } = useWarranty()
  const [showRejected, setShowRejected] = useState(false)
  const [updating, setUpdating] = useState(false)

  const updateStatus = useCallback(
    (status: Status, reason: string | undefined) => {
      setUpdating(true)
      const updates = {
        status,
        ...(reason
          ? { rejection_reason: reason, rejected_at: new Date() }
          : {}),
      }
      services.warranty
        .update(warranty.id, updates)
        .then(() => {
          setUpdating(false)
          onUpdate({ ...warranty, ...updates })
        })
        .catch(() => setUpdating(false))
    },
    [warranty, onUpdate, services],
  )

  const selectStatus = useCallback(
    (status: Status) => {
      if (status.key !== warranty.status?.key) {
        if (status.reason) {
          setShowRejected(true)
        } else {
          updateStatus(status, undefined)
        }
      }
    },
    [warranty, updateStatus],
  )

  const selectReason = useCallback(
    (reason: string) => {
      setShowRejected(false)
      updateStatus(
        WARRANTY_STATUSES.find((status) => !!status.reason),
        reason,
      )
    },
    [updateStatus],
  )

  const textColour = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Flex alignItems="center" direction="column" gap="1rem" paddingY="2rem">
      <Flex alignItems="center" gap="1rem" justifyContent="center">
        <Box>
          <Text
            color="secondaryGray.600"
            fontSize="md"
            fontWeight="500"
            textAlign="center"
          >
            Status
          </Text>
          <Flex align="center" justifyContent="center">
            <Text color={textColour} textAlign="center">
              <Text
                as="span"
                fontWeight="700"
                fontSize="lg"
                color={warranty.status?.colour ?? textColour}
              >
                {warranty.status?.title ?? '-'}
              </Text>
              {warranty.status?.reason && warranty.rejection_reason
                ? ` (${warranty.rejection_reason})`
                : null}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="center" gap="1rem">
        <WarrantyStatusMenu
          label="Update"
          selected={warranty.status}
          onSelect={selectStatus}
          disabled={updating}
        />
        {!warranty.status?.fixed ? (
          <Button
            as={Link}
            to={reverse(routes.warranties.renew, { warrantyId: warranty.id })}
            colorScheme="green"
            rightIcon={<MdRefresh />}
          >
            Renew
          </Button>
        ) : warranty.renewals?.length > 0 ? (
          <Button
            as={Link}
            variant="brand"
            to={reverse(routes.warranties.detail, {
              warrantyId: warranty.renewals[0].id,
            })}
            rightIcon={<MdVisibility />}
          >
            View Renewal
          </Button>
        ) : null}
      </Flex>
      <WarrantyRejectedModal
        open={showRejected}
        onSelect={selectReason}
        onCancel={() => setShowRejected(false)}
      />
    </Flex>
  )
}

export default WarrantyStatus
