import { Select, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseField, FieldProps } from './BaseField'
import { getFieldOptions } from '../../../../utils'

export function SelectField<T extends object>(props: FieldProps) {
  const { field, extras } = props
  const { register } = useFormContext<T>()

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <BaseField {...props}>
      <Select
        fontSize="sm"
        fontWeight="500"
        size="lg"
        color={textPrimary}
        {...register(field.name as any, getFieldOptions(field))}
      >
        {(extras?.options ?? []).map((option, index) => (
          <option key={index} value={option.value}>
            {option.title}
          </option>
        ))}
      </Select>
    </BaseField>
  )
}
