import { useDispatch, useSelector } from 'react-redux'
import { getWarranty, loadWarranty, setWarranty } from '../store/warranties'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Garage,
  type PaginatedResponse,
  Product,
  User,
  WarrantiesMeta,
  Warranty,
  WarrantyStatus,
} from '../types'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../store'
import { AvailableServices, ServiceContext } from '../components/Services'
import { useQueryParams } from './queryParams'
import { useLoading } from './misc'
import { WarrantyKey } from '../services/LoadingService'

export type WarrantyTableDataQuery = {
  garage?: Garage
  product?: Product
  user?: User
  assignment?: boolean
  filters?: boolean
  unassigned?: boolean
  summary?: boolean
}

export type WarrantyTableData = {
  warranties: PaginatedResponse<Warranty, WarrantiesMeta> | null
  loadWarranties: (query?: WarrantyTableDataQuery) => void
  loadingWarranties: boolean
}

export const useWarrantyTableData = (
  query: WarrantyTableDataQuery = {},
  ready: boolean = true,
  queryParamKey: string = 'url',
): WarrantyTableData => {
  const services: AvailableServices = useContext(ServiceContext)
  const { params } = useQueryParams(queryParamKey)

  const queryRef = useRef<string>('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PaginatedResponse<
    Warranty,
    WarrantiesMeta
  > | null>(null)

  const loadData = useCallback(() => {
    const { garage, product, user, filters, assignment, unassigned, summary } =
      query
    if (ready && params !== null) {
      setLoading(true)
      services.warranty
        .list({
          ...params,
          ...(garage ? { garage: [garage.id] } : {}),
          ...(product ? { product: product.id } : {}),
          ...(user ? { user: user.id } : {}),
          assignment,
          ...(unassigned ? { user: 0 } : {}),
          ...(summary || filters
            ? {
                meta: [
                  ...(summary ? ['summary'] : []),
                  ...(filters
                    ? ['types', 'garages', 'lengths', 'statuses']
                    : []),
                ],
              }
            : {}),
        })
        .then((response: PaginatedResponse<Warranty, WarrantiesMeta>) => {
          setLoading(false)
          setData(response)
        })
        .catch(() => {
          setLoading(false)
          setData(null)
        })
    }
  }, [ready, services, params, query])

  useEffect(() => {
    const {
      page,
      length,
      search,
      sort,
      garage: paramsGarage,
      user: paramsUser,
      type,
      duration,
      status,
    } = params
    const { garage, product, user, filters, unassigned, summary } = query
    const serialised = JSON.stringify({
      page,
      length,
      search,
      sort,
      garage: paramsGarage ?? garage?.id,
      product: product?.id,
      user: paramsUser ?? user?.id,
      filters,
      unassigned,
      summary,
      type,
      duration,
      status,
    })
    if (serialised !== queryRef.current) {
      queryRef.current = serialised
      loadData()
    }
  }, [loadData, params, query])

  return {
    warranties: data,
    loadWarranties: loadData,
    loadingWarranties: loading,
  }
}

type WarrantyParams = {
  warrantyId?: string
}

export const useWarranty = () => {
  const { warrantyId } = useParams<WarrantyParams>()
  const { warranty, exception } = useSelector(getWarranty)
  const { loading, service } = useLoading(new WarrantyKey(warrantyId))
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (
      warrantyId &&
      warrantyId !== `${warranty?.id}` &&
      !service.isLoading(new WarrantyKey(warrantyId))
    ) {
      dispatch(loadWarranty(parseInt(warrantyId)))
    }
  }, [warranty, warrantyId, service, dispatch])

  const updateWarranty = useCallback(
    (warranty: Warranty | null) => {
      dispatch(setWarranty(warranty))
    },
    [dispatch],
  )

  return {
    warranty,
    loading,
    exception,
    onUpdate: updateWarranty,
  }
}

export const useWarrantyFilters = () => {
  const { params, updateParams } = useQueryParams()
  const [garages, setGarages] = useState<number[]>([])
  const [types, setTypes] = useState<string[]>([])
  const [durations, setDurations] = useState<number[]>([])
  const [statuses, setStatuses] = useState<string[]>([])

  useEffect(() => {
    setGarages(params.garage ?? [])
    setTypes(params.type ?? [])
    setDurations(params.duration ?? [])
    setStatuses(params.status ?? [])
  }, [params])

  const onSelectGarage = useCallback(
    (garage: Garage | undefined) => {
      if (garage) {
        const updated = [...garages]
        const index = updated.indexOf(garage.id)
        if (index < 0) {
          updated.push(garage.id)
        } else {
          updated.splice(index, 1)
        }
        setGarages(updated)
      } else {
        setGarages([])
      }
    },
    [garages],
  )

  const onSelectType = useCallback(
    (type: string | undefined) => {
      if (type) {
        const updated = [...types]
        const index = updated.indexOf(type)
        if (index < 0) {
          updated.push(type)
        } else {
          updated.splice(index, 1)
        }
        setTypes(updated)
      } else {
        setTypes([])
      }
    },
    [types],
  )

  const onSelectDuration = useCallback(
    (duration: number | undefined) => {
      if (duration) {
        const updated = [...durations]
        const index = updated.indexOf(duration)
        if (index < 0) {
          updated.push(duration)
        } else {
          updated.splice(index, 1)
        }
        setDurations(updated)
      } else {
        setDurations([])
      }
    },
    [durations],
  )

  const onSelectStatus = useCallback(
    (status: WarrantyStatus | undefined) => {
      if (status) {
        const updated = [...statuses]
        const index = updated.indexOf(status.key)
        if (index < 0) {
          updated.push(status.key)
        } else {
          updated.splice(index, 1)
        }
        setStatuses(updated)
      } else {
        setStatuses([])
      }
    },
    [statuses],
  )

  const commitParams = useCallback(
    (
      garages: number[] = [],
      types: string[] = [],
      durations: number[] = [],
      statuses: string[] = [],
    ) => {
      updateParams({
        garage: garages.length > 0 ? garages : undefined,
        type: types.length > 0 ? types : undefined,
        duration: durations.length > 0 ? durations : undefined,
        status: statuses.length > 0 ? statuses : undefined,
      })
    },
    [updateParams],
  )

  const canApply = useMemo(
    () =>
      garages.length > 0 ||
      types.length > 0 ||
      durations.length > 0 ||
      statuses.length > 0,
    [garages, types, durations, statuses],
  )

  const applyFilters = useCallback(
    async () =>
      new Promise<void>((resolve) => {
        commitParams(garages, types, durations, statuses)
        resolve()
      }),
    [commitParams, garages, types, durations, statuses],
  )

  const canClear = useMemo(() => {
    return (
      !!params.garage || !!params.type || !!params.duration || !!params.status
    )
  }, [params])

  const clearFilters = useCallback(
    async () =>
      new Promise<void>((resolve) => {
        commitParams()
        resolve()
      }),
    [commitParams],
  )

  return {
    garages,
    onSelectGarage,
    types,
    onSelectType,
    durations,
    onSelectDuration,
    statuses,
    onSelectStatus,
    canApply,
    applyFilters,
    canClear,
    clearFilters,
  }
}
