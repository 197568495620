import type {
  PaginatedResponse,
  PaginationMeta,
  PaginationMetaResponse,
  QueryParams,
  TableRequest,
} from '../types'

export const mapPaginatedResponse = <MR, MM, PR, MP>(
  response: PaginatedResponse<MR, PR>,
  mapper: (entity: any) => any,
  paginationMapper?: (entity: any) => any,
): PaginatedResponse<MM, MP> => {
  const { data, meta } = response
  return {
    data: data.map(mapper),
    meta: paginationMapper(meta),
  }
}

export const mapPaginationMeta = (
  response: PaginationMetaResponse,
): PaginationMeta => {
  return {
    length: response.per_page,
    currentPage: response.current_page,
    lastPage: response.last_page,
    nextPage:
      response.current_page < response.last_page
        ? response.current_page + 1
        : null,
    previousPage: response.current_page > 1 ? response.current_page - 1 : null,
    from: response.from,
    to: response.to,
    total: response.total,
  }
}

export const mapTableQuery = (data: QueryParams): TableRequest => {
  return {
    page: data.page ?? 1,
    ...(data.length ? { length: data.length } : {}),
    ...(data.sort ? { sort: data.sort } : {}),
    ...(data.include ? { include: data.include.join(',') } : {}),
    ...(data.meta ? { meta: data.meta.join(',') } : {}),
    filter: {
      ...(data.search ? { search: data.search } : {}),
      ...(data.garage ? { garage: `${data.garage}` } : {}),
      ...(data.role ? { role: data.role.slug } : {}),
      ...(data.product != null ? { product: `${data.product}` } : {}),
      ...(data.user != null ? { user: `${data.user}` } : {}),
      ...(data.archive ? { archive: 'only' } : {}),
      ...(data.assignment ? { assignment: '1' } : {}),
      ...(data.type ? { type: `${data.type}` } : {}),
      ...(data.duration ? { duration: `${data.duration}` } : {}),
      ...(data.status ? { status: `${data.status}` } : {}),
      ...(data.unassigned ? { unassigned: '1' } : {}),
      ...(data.incomplete ? { incomplete: '1' } : {}),
    },
  }
}
