import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import Card from '../../../../horizon/components/card/Card'

export type Step = {
  title: string
  content: React.ReactElement
}

const SteppedContent: React.FunctionComponent<{
  steps: Step[]
  currentStep: number
}> = ({ steps, currentStep }) => {
  const textColour = useColorModeValue('secondaryGray.900', 'white')

  return (
    <Flex
      direction="column"
      minHeight="100vh"
      align="center"
      paddingTop={{ sm: '125px', lg: '2rem' }}
      position="relative"
    >
      <Box
        height="240px"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        width="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        marginTop={{ base: '60px' }}
        display="flex"
        flexDirection="column"
        index={currentStep}
      >
        <TabList
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifySelf="center"
        >
          {steps.map(({ title }, index) => (
            <Tab
              width={{ sm: '80px', md: '120px', lg: '160px' }}
              key={index}
              cursor="default"
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={
                  index < steps.length - 1
                    ? {
                        content: "''",
                        width: { sm: '80px', md: '120px', lg: '160px' },
                        height: '3px',
                        bg: currentStep > index ? 'white' : 'brand.400',
                        left: { sm: '12px', md: '30px' },
                        top: {
                          sm: '6px',
                          md: null,
                        },
                        position: 'absolute',
                        bottom: currentStep === index ? '40px' : '38px',
                        transition: 'all .3s ease',
                      }
                    : {}
                }
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={currentStep >= index ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={currentStep === index ? 'white' : 'gray.300'}
                  fontWeight={currentStep === index ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  {title}
                </Text>
              </Flex>
            </Tab>
          ))}
        </TabList>
        <TabPanels
          marginTop="24px"
          maxWidth={{ md: '90%', lg: '100%' }}
          marginX="auto"
        >
          {steps.map(({ title, content }, index) => (
            <TabPanel
              width={{ sm: '330px', md: '700px', lg: '850px' }}
              padding="0"
              marginX="auto"
              key={index}
            >
              <Card padding="30px">
                <Text
                  color={textColour}
                  fontSize="2xl"
                  fontWeight="700"
                  marginBottom="20px"
                >
                  {title}
                </Text>
                <Flex direction="column" width="100%">
                  {content}
                </Flex>
              </Card>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default SteppedContent
