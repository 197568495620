import React, { useCallback } from 'react'
import { Morph, Note } from '../../../../types'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import NoteDetails from './NoteDetails'

const NoteModal: React.FunctionComponent<{
  morph: Morph
  open: boolean
  onSaved?: (note: Note) => void
  onClose: () => void
}> = ({ morph, open, onSaved, onClose }) => {
  const handleSaved = useCallback(
    (note: Note) => {
      if (onSaved) {
        onSaved(note)
      }
      onClose()
    },
    [onSaved, onClose],
  )

  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minWidth="calc(100vh - 4rem)"
        maxWidth="600px"
        paddingBottom="1rem"
      >
        <ModalHeader>Add New Note</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <NoteDetails morph={morph} onSaved={handleSaved} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NoteModal
