import React, { useCallback, useContext, useState } from 'react'
import FileCard from '../../molecules/shared/FileCard'
import { useProduct } from '../../../../hooks'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import { useToast } from '@chakra-ui/react'

const ProductBooklet: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const { product, onUpdate } = useProduct()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleUpload = useCallback(
    (file: File) => {
      setLoading(true)
      services.product
        .uploadBooklet(product.id, file)
        .then((updated) => {
          onUpdate(updated)
          setLoading(false)
          toast({
            title: 'Booklet Uploaded',
            description: `The booklet for ${updated.name} has been successfully updated`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          setLoading(false)
          setError(
            'Sorry, there was a problem uploading the file. Please make sure it is a valid document',
          )
        })
    },
    [services, toast, product, onUpdate],
  )

  const handleDownload = useCallback(() => {
    setLoading(true)
    services.product
      .downloadBooklet(product.id)
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false)
        setError('Sorry, there was a problem downloading the file')
      })
  }, [services, product])

  const handleDelete = useCallback(() => {
    setLoading(true)
    services.product
      .deleteBooklet(product.id)
      .then((updated) => {
        onUpdate(updated)
        setLoading(false)
        toast({
          title: 'Booklet Deleted',
          description: `The booklet for ${updated.name} has been successfully deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch(() => {
        setLoading(false)
        setError('Sorry, there was a problem deleting the file')
      })
  }, [services, toast, product, onUpdate])

  return (
    <FileCard
      title="Booklet"
      subtitle="This is the booklet associated with the product which is sent to customers via email"
      uploaded={product.has_booklet}
      fileName={product.booklet_name ?? undefined}
      onUpload={handleUpload}
      onDownload={handleDownload}
      onDelete={handleDelete}
      fill
      error={error}
      loading={loading}
    />
  )
}

export default ProductBooklet
