import { TaskType } from '../types'

export const TASK_TYPES: TaskType[] = [
  {
    name: 'Callback',
    slug: 'callback',
  },
  {
    name: 'Email',
    slug: 'email',
  },
  {
    name: 'Send Promo',
    slug: 'promo',
  },
  {
    name: 'Send Trustpilot Link',
    slug: 'trustpilot',
  },
  {
    name: 'Other',
    slug: 'other',
  },
]
