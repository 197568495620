import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '../../molecules/shared/DataTable'
import type {
  PaginatedResponse,
  PaginationMeta,
  Product,
} from '../../../../types'
import { format } from 'date-fns'
import { accessorColumn, DATE_FORMAT } from '../../../../utils'
import Actions from '../../molecules/shared/Actions'
import { MdAdd, MdVisibility } from 'react-icons/md'
import { reverse } from 'named-urls'
import routes from '../../../../routes'
import TableButtons from '../../molecules/shared/TableButtons'
import { useTableSort } from '../../../../hooks'
import { Badge } from '@chakra-ui/react'

type ProductRow = {
  id: number
  name: string
  status: React.ReactElement
  dateAdded: string
}

const ProductsTable: React.FunctionComponent<{
  data: PaginatedResponse<Product, PaginationMeta>
  loading: boolean
}> = ({ data, loading }) => {
  const { sorting, onSortingChange } = useTableSort()

  const rows = useMemo<ProductRow[]>(
    () =>
      (data?.data ?? []).map((product: Product) => ({
        id: product.id,
        name: product.name,
        status: product.paused_at ? (
          <Badge colorScheme="red">Paused</Badge>
        ) : (
          <Badge colorScheme="green">Available</Badge>
        ),
        dateAdded: format(product.created_at, DATE_FORMAT),
      })),
    [data],
  )

  const columnHelper = createColumnHelper<ProductRow>()
  const columns = [
    accessorColumn<ProductRow, string>(columnHelper, 'name', 'Name', {
      sorting: true,
    }),
    accessorColumn<ProductRow, string>(columnHelper, 'status', 'Status', {
      sorting: true,
    }),
    accessorColumn<ProductRow, string>(
      columnHelper,
      'dateAdded',
      'Date Added',
      { sorting: true },
    ),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              {
                title: 'View',
                icon: MdVisibility,
                route: reverse(routes.products.detail, {
                  productId: cell.row.original.id,
                }),
              },
            ]}
          />
        ),
        [],
      ),
    }),
  ]

  return (
    <DataTable
      meta={data?.meta}
      rows={rows}
      columns={columns as any}
      loading={loading}
      sorting={sorting}
      onSortingChange={onSortingChange}
      actions={
        <TableButtons
          buttons={[
            {
              title: 'Add New Product',
              icon: MdAdd,
              route: routes.products.add,
            },
          ]}
        />
      }
    />
  )
}

export default ProductsTable
