import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Alert,
  AlertIcon,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import { useGarage } from '../../../../hooks'
import SwitchSelector, {
  SwitchOption,
} from '../../molecules/shared/SwitchSelector'

const GarageContact: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const { garage, onUpdate } = useGarage()
  const [noContact, setNoContact] = useState<boolean | undefined>(undefined)
  const option = useMemo<SwitchOption>(
    () => ({
      title: garage.no_contact ? 'No Contact' : 'OK',
      value: 'noContact',
    }),
    [garage],
  )

  useEffect(() => {
    setNoContact(garage.no_contact)
  }, [garage])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSelect = useCallback(() => {
    setNoContact(!noContact)
    setError('')
    setLoading(true)
    services.garage
      .update(garage.id, {
        no_contact: !noContact,
      })
      .then((updated) => {
        setLoading(false)
        onUpdate(updated)
      })
      .catch(() => {
        setLoading(false)
        setError('Sorry, there was a problem updating this garage')
      })
  }, [services, noContact, garage, onUpdate])

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textSecondary = 'secondaryGray.600'
  return (
    <Card flex={1}>
      <Flex direction="column" paddingBottom="1rem">
        <Text fontSize="xl" color={textPrimary} fontWeight="bold">
          Contact
        </Text>
        <Text fontSize="md" color={textSecondary}>
          Here you can choose to stop contact with this garage which will stop
          their warranties from appearing when assigning agents
        </Text>
      </Flex>
      {error ? (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      ) : null}
      <SwitchSelector
        options={[option]}
        selected={noContact ? undefined : option}
        onSelect={onSelect}
        isDisabled={loading}
      />
    </Card>
  )
}

export default GarageContact
