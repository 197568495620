import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getHours,
  getMinutes,
  isPast,
  isSameDay,
  isSameMonth,
  setHours,
  setMinutes,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  subMonths,
} from 'date-fns'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

enum Shortcut {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Week = 'In a Week',
  Fortnight = 'In a Fortnight',
  Month = 'In 4 Weeks',
}

type Day = {
  date: Date
  label?: string
  isToday?: boolean
  isSelected?: boolean
  isPast?: boolean
}

const DatePicker: React.FunctionComponent<{
  value: Date | null
  onChange: (date: Date | null) => void
  includeTime?: boolean
}> = ({ value, onChange, includeTime }) => {
  const [calendarDate, setCalendarDate] = useState<Date>(
    startOfMonth(startOfDay(new Date())),
  )
  const monthRef = useRef<Date>(calendarDate)
  const start = useMemo(() => startOfISOWeek(calendarDate), [calendarDate])
  const weeks = useMemo(() => {
    const weeks: Array<Day[]> = []
    let date = start
    while (true) {
      const week: Day[] = []
      for (let i = 0; i < 7; i++) {
        const isToday = isSameDay(date, new Date())
        week.push({
          date,
          ...(isSameMonth(date, calendarDate)
            ? {
                label: format(date, 'd'),
                isToday,
                isSelected: value && isSameDay(date, value),
                isPast: !isToday && isPast(date),
              }
            : {}),
        })
        date = addDays(date, 1)
      }
      weeks.push(week)
      if (!isSameMonth(date, calendarDate)) {
        break
      }
    }
    return weeks
  }, [value, calendarDate, start])

  useEffect(() => {
    monthRef.current = calendarDate
  }, [calendarDate])

  useEffect(() => {
    if (value && !isSameMonth(value, monthRef.current)) {
      setCalendarDate(startOfMonth(value))
    }
  }, [value])

  const handlePrevious = useCallback(() => {
    setCalendarDate(subMonths(calendarDate, 1))
  }, [calendarDate])

  const handleNext = useCallback(() => {
    setCalendarDate(addMonths(calendarDate, 1))
  }, [calendarDate])

  const changeDay = useCallback(
    (date: Date) => {
      if (includeTime) {
        onChange(
          setMinutes(
            setHours(date, value ? getHours(value) : 9),
            value ? getMinutes(value) : 0,
          ),
        )
      } else {
        onChange(startOfDay(date))
      }
    },
    [value, onChange, includeTime],
  )

  const handleShortcut = useCallback(
    (shortcut: Shortcut) => {
      let date = new Date()
      switch (shortcut) {
        case Shortcut.Today:
          date = addDays(date, 0)
          break
        case Shortcut.Tomorrow:
          date = addDays(date, 1)
          break
        case Shortcut.Week:
          date = addWeeks(date, 1)
          break
        case Shortcut.Fortnight:
          date = addWeeks(date, 2)
          break
        case Shortcut.Month:
          date = addWeeks(date, 4)
      }
      changeDay(date)
    },
    [changeDay],
  )

  const handleHour = useCallback(
    (e) => {
      onChange(setHours(value ?? startOfDay(new Date()), e.target.value))
    },
    [value, onChange],
  )

  const handleMinute = useCallback(
    (e) => {
      onChange(setMinutes(value ?? startOfDay(new Date()), e.target.value))
    },
    [value, onChange],
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const brandColour = useColorModeValue('brand.500', 'white')
  const dayBackground = useColorModeValue('white', 'secondaryGray.900')
  const dayText = useColorModeValue('secondaryGray.900', 'white')
  const selectedBackground = useColorModeValue('brand.500', 'white')
  const selectedText = useColorModeValue('white', 'brand.500')
  const borderColour = useColorModeValue('secondaryGray.100', 'whiteAlpha.100')
  return (
    <>
      <Grid templateColumns="repeat(6, 1fr)" width="100%">
        <GridItem colSpan={4} as={Flex} direction="column">
          <Flex alignItems="center" gap="1rem" padding="1rem">
            <IconButton
              variant="brand"
              icon={<Icon color="white" as={MdChevronLeft} w="20px" h="20px" />}
              aria-label="Previous"
              onClick={handlePrevious}
            />
            <Text
              color={brandColour}
              flex={1}
              fontSize="lg"
              fontWeight="semibold"
              textAlign="center"
            >
              {format(calendarDate, 'MMMM yyyy')}
            </Text>
            <IconButton
              variant="brand"
              icon={
                <Icon color="white" as={MdChevronRight} w="20px" h="20px" />
              }
              aria-label="Next"
              onClick={handleNext}
            />
          </Flex>
        </GridItem>
        <GridItem
          borderLeftColor={borderColour}
          borderLeftWidth={1}
          colSpan={2}
        />
        <GridItem
          as={Grid}
          colSpan={4}
          padding="1rem"
          templateColumns="repeat(7, 1fr)"
        >
          {[...Array(7).keys()].map((i) => (
            <GridItem
              key={i}
              as={Text}
              alignItems="center"
              display="flex"
              fontWeight="semibold"
              justifyContent="center"
              padding="0.5rem"
              textAlign="center"
            >
              {format(addDays(start, i), 'EEE')}
            </GridItem>
          ))}
          {weeks.map((week, weekIndex) =>
            week.map((day, dayIndex) => (
              <GridItem
                key={`${weekIndex}-${dayIndex}`}
                as="button"
                alignItems="center"
                backgroundColor={
                  day.isSelected ? selectedBackground : dayBackground
                }
                color={day.isSelected ? selectedText : dayText}
                disabled={day.isPast}
                display="flex"
                fontWeight={day.isToday ? 'bold' : 'normal'}
                justifyContent="center"
                onClick={() => changeDay(day.date)}
                opacity={day.isPast ? 0.3 : 1}
                padding="0.5rem"
                textAlign="center"
                type="button"
              >
                {day.label ?? ''}
              </GridItem>
            )),
          )}
        </GridItem>
        <GridItem
          as={Flex}
          borderLeftColor={borderColour}
          borderLeftWidth={1}
          colSpan={2}
          flexDirection="column"
          gap="0.5rem"
          padding="1rem"
        >
          <Text
            color={textPrimary}
            fontWeight="semibold"
            marginTop="0.5rem"
            textAlign="center"
          >
            Shortcuts
          </Text>
          {Object.values(Shortcut).map((shortcut, index) => (
            <button
              color={textPrimary}
              key={index}
              type="button"
              onClick={() => handleShortcut(shortcut)}
            >
              {shortcut}
            </button>
          ))}
        </GridItem>
        {includeTime ? (
          <>
            <GridItem
              alignItems="center"
              as={Flex}
              colSpan={4}
              gap="1rem"
              justifyContent="center"
              padding="1rem"
            >
              <Text
                color={textPrimary}
                fontWeight="semibold"
                textAlign="center"
              >
                Time
              </Text>
              <Select
                color={textPrimary}
                flex="0 0 100px"
                fontSize="sm"
                fontWeight="500"
                size="lg"
                value={value ? getHours(value) : 0}
                onChange={handleHour}
              >
                {[...Array(24).keys()].map((hour) => (
                  <option key={hour} value={hour}>
                    {`${hour}`.padStart(2, '0')}
                  </option>
                ))}
              </Select>
              <Text
                color={textPrimary}
                fontWeight="semibold"
                textAlign="center"
              >
                :
              </Text>
              <Select
                color={textPrimary}
                flex="0 0 100px"
                fontSize="sm"
                fontWeight="500"
                size="lg"
                value={value ? getMinutes(value) : 0}
                onChange={handleMinute}
              >
                {[...Array(12).keys()].map((minute) => (
                  <option key={minute} value={minute * 5}>
                    {`${minute * 5}`.padStart(2, '0')}
                  </option>
                ))}
              </Select>
            </GridItem>
            <GridItem
              borderLeftColor={borderColour}
              borderLeftWidth={1}
              colSpan={2}
            />
          </>
        ) : null}
      </Grid>
    </>
  )
}

export default DatePicker
