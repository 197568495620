import React from 'react'

export enum FormFieldType {
  Text = 'text',
  Textarea = 'textarea',
  Email = 'email',
  Password = 'password',
  Select = 'select',
  Date = 'date',
  DateTime = 'datetime',
  Spacer = 'spacer',
}

export type FormField = {
  name?: string
  label?: string
  requiredError?: string
  type: FormFieldType
  validation?: (value: any, formValues: any) => string | undefined
  colSpan?: number
  leftAddon?: string
  rightAddon?: string
}

export type FormFieldOption = {
  title: string
  value: string | number
}

export type FormFieldExtras = {
  options?: FormFieldOption[]
  isHidden?: boolean
  content?: React.ReactElement
}

export type FormConfig = {
  fields: FormField[]
  columns?: number
}
