import type { Task, TaskResponse, UpdateTask, UpdateTaskData } from '../types'
import { mapUser } from './auth'
import { mapMorphRelation } from './misc'
import { DATABASE_DATETIME_FORMAT } from '../utils'
import { format, setSeconds } from 'date-fns'

export const mapTask = (data: TaskResponse): Task => {
  const { subject, ...attrs } = data
  return {
    ...attrs,
    subject: subject ? mapMorphRelation(subject.type, subject.data) : null,
    due_at: data.due_at ? new Date(data.due_at) : null,
    assigned_to: data.assigned_to ? mapUser(data.assigned_to) : null,
    completed_at: data.completed_at ? new Date(data.completed_at) : null,
    completed_by: data.completed_by ? mapUser(data.completed_by) : null,
    is_complete: data.completed_at != null,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }
}

export const mapUpdateTask = (data: UpdateTask): UpdateTaskData => {
  const { morph, dueDate, assignedTo, ...attrs } = data
  return {
    ...attrs,
    ...(morph ? { subject_id: morph.id, subject_type: morph.type } : {}),
    ...(dueDate
      ? { due_at: format(setSeconds(dueDate, 0), DATABASE_DATETIME_FORMAT) }
      : {}),
    ...(assignedTo !== undefined
      ? { assigned_to_id: assignedTo ? assignedTo : null }
      : {}),
  }
}
