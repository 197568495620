import React, { useCallback, useContext, useState } from 'react'
import { Alert, AlertIcon, Button, Flex, SimpleGrid } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import { Morph, Note } from '../../../../types'
import Card from '../../../../horizon/components/card/Card'
import GridForm from '../forms/GridForm'
import { newNote, NewNoteFields } from '../../../../forms'

const NoteDetails: React.FunctionComponent<{
  morph: Morph
  onSaved: (note: Note) => void
}> = ({ morph, onSaved }) => {
  const services: AvailableServices = useContext(ServiceContext)
  const form = useForm<NewNoteFields>()
  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)

  const onSubmit = useCallback(
    (input: NewNoteFields) => {
      setError('')
      setSaving(true)
      services.note
        .create({ ...input, morph })
        .then((saved: Note) => {
          setSaving(false)
          onSaved(saved)
        })
        .catch(() => {
          setSaving(false)
          setError('Sorry, there was a problem saving your note')
        })
    },
    [morph, services, onSaved],
  )

  return (
    <FormProvider {...form}>
      <Flex
        direction="column"
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate={true}
        gap="1rem"
      >
        <Card display="flex" flexDirection="column">
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1rem">
            {error ? (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            ) : null}
            <GridForm config={newNote} isDisabled={saving} />
          </SimpleGrid>
        </Card>
        <Flex justifyContent="flex-end">
          <Button colorScheme="brand" type="submit" isDisabled={saving}>
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  )
}

export default NoteDetails
