import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Alert, AlertIcon, Button, Flex, SimpleGrid } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import { useProduct } from '../../../../hooks'
import { Product, ProductTariff, ProductTariffUpdate } from '../../../../types'
import Card from '../../../../horizon/components/card/Card'
import GridForm from '../forms/GridForm'
import { tariffAdminDetails, tariffValueDetails } from '../../../../forms'

const TariffDetails: React.FunctionComponent<{
  tariff?: ProductTariff
  onSaved: () => void
}> = ({ tariff, onSaved }) => {
  const services: AvailableServices = useContext(ServiceContext)
  const { product, onUpdate: onUpdatedProduct } = useProduct()
  const form = useForm<ProductTariffUpdate>()
  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    form.setValue('duration', tariff?.duration ?? 6)
    form.setValue('plan', tariff?.plan ? tariff.plan.value : 1)
    form.setValue('claimLimit', tariff?.claim_limit ?? 0)
    form.setValue('adminFee', tariff?.admin_fee ?? 0)
    form.setValue('premium', tariff?.premium ?? 0)
    form.setValue('grossPrice', tariff?.gross_price ?? 0)
    form.setValue('vat', tariff?.vat ?? 0)
    form.setValue('netPrice', tariff?.net_price ?? 0)
    form.setValue('initial', tariff?.initial ?? 0)
  }, [tariff, form])

  const onSubmit = useCallback(
    (input: ProductTariffUpdate) => {
      setError('')
      setSaving(true)
      const done = (updated: Product) => {
        setSaving(false)
        onUpdatedProduct(updated)
        onSaved()
      }
      const error = () => {
        setSaving(false)
        setError('Sorry, there was a problem saving the details you entered')
      }
      if (tariff) {
        services.product
          .updateTariff(product, tariff.id, input)
          .then(done)
          .catch(error)
      } else {
        services.product.createTariff(product, input).then(done).catch(error)
      }
    },
    [tariff, services, product, onUpdatedProduct, onSaved],
  )

  return (
    <FormProvider {...form}>
      <Flex
        direction="column"
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate={true}
        gap="1rem"
      >
        <Card display="flex" flexDirection="column">
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="1rem">
            {error ? (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            ) : null}
            <GridForm
              config={tariffAdminDetails}
              extras={{
                plan: {
                  options: [
                    { value: 1, title: 'Full Payment' },
                    { value: 3, title: '3 Instalments' },
                    { value: 6, title: '6 Instalments' },
                    { value: 12, title: '12 Instalments' },
                  ],
                },
              }}
              isDisabled={saving}
            />
            <GridForm config={tariffValueDetails} isDisabled={saving} />
          </SimpleGrid>
        </Card>
        <Flex justifyContent="flex-end">
          <Button colorScheme="brand" type="submit" isDisabled={saving}>
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  )
}

export default TariffDetails
