import { FormConfig, FormFieldType } from '../types'

export type NewNoteFields = {
  body: string
}

export const newNote: FormConfig = {
  fields: [
    {
      name: 'body',
      label: 'Body',
      requiredError: 'Please enter a message to save with this note',
      type: FormFieldType.Textarea,
    },
  ],
}
