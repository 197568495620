import React, { useMemo } from 'react'
import {
  Flex,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import { MdBarChart, MdFileCopy } from 'react-icons/md'
import { WarrantiesSummary } from '../../../../types'
import { formatInteger, WARRANTY_STATUSES } from '../../../../utils'

type Statistic = {
  title: string
  value: string
  icon: React.ReactElement
  colour?: string
}

const Summary: React.FunctionComponent<{
  statistic: Statistic
  loading: boolean
}> = ({ statistic: { title, value, icon, colour }, loading }) => {
  const titleColour = useColorModeValue('secondaryGray.700', 'white')
  const valueColour = useColorModeValue('secondaryGray.900', 'white')
  const spinnerColour = useColorModeValue('brand.500', 'white')
  return (
    <Card
      alignItems="center"
      display="flex"
      flexDirection="row"
      gap="1rem"
      justifyContent="center"
      padding="1rem"
      position="relative"
    >
      <Flex
        alignItems="center"
        backgroundColor={colour ?? 'gray.200'}
        borderRadius="1.25rem"
        flex="0 0 2.5rem"
        height="2.5rem"
        justifyContent="center"
        opacity={loading ? 0.25 : 1}
        width="2.5rem"
      >
        {icon}
      </Flex>
      <Flex flex={1} direction="column" opacity={loading ? 0.25 : 1}>
        <Text fontSize="0.875rem" color={titleColour}>
          {title}
        </Text>
        <Text fontSize="1.125rem" fontWeight="600" color={valueColour}>
          {value}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        bottom="0"
        justifyContent="center"
        left="0"
        opacity={loading ? 1 : 0}
        padding="2rem"
        position="absolute"
        pointerEvents={loading ? 'all' : 'none'}
        right="0"
        top="0"
        transition="opacity 0.25s ease-in-out"
      >
        <Spinner color={spinnerColour} size="xl" />
      </Flex>
    </Card>
  )
}

const WarrantyAdminSummary: React.FunctionComponent<{
  summary?: WarrantiesSummary
  loading: boolean
}> = ({ summary, loading }) => {
  const stats: Statistic[] = useMemo(
    () => [
      {
        title: 'Total Warranties',
        value:
          summary?.warranty_count !== undefined
            ? formatInteger(summary.warranty_count)
            : '-',
        icon: <MdBarChart />,
      },
      {
        title: 'Open Tasks',
        value:
          summary?.task_count !== undefined
            ? formatInteger(summary.task_count)
            : '-',
        icon: <MdFileCopy />,
      },
      ...WARRANTY_STATUSES.filter(({ summarised }) => summarised).map(
        ({ title, key, colour, contrastColour, icon: Icon }) => ({
          title: title,
          value:
            (summary?.status_counts ?? {})[key] !== undefined
              ? formatInteger(summary.status_counts[key])
              : '-',
          icon: <Icon color={contrastColour} />,
          colour: colour,
        }),
      ),
    ],
    [summary],
  )

  return (
    <SimpleGrid columns={stats.length} padding="2rem 0" gap="1rem">
      {stats.map((stat, index) => (
        <Summary statistic={stat} key={index} loading={loading} />
      ))}
    </SimpleGrid>
  )
}

export default WarrantyAdminSummary
