import React, { createContext, PropsWithChildren } from 'react'
import { AuthService } from '../services/AuthService'
import { ServiceProvider } from '../services/ServiceProvider'
import { UserService } from '../services/UserService'
import { WarrantyService } from '../services/WarrantyService'
import { GarageService } from '../services/GarageService'
import { ProductService } from '../services/ProductService'
import { LoadingService } from '../services/LoadingService'
import { NoteService } from '../services/NoteService'
import { TaskService } from '../services/TaskService'

export type AvailableServices = {
  auth: AuthService | null
  garage: GarageService | null
  loading: LoadingService | null
  note: NoteService | null
  product: ProductService | null
  task: TaskService | null
  user: UserService | null
  warranty: WarrantyService | null
}

export const ServiceContext: React.Context<AvailableServices> = createContext({
  auth: null,
  garage: null,
  loading: null,
  note: null,
  product: null,
  task: null,
  user: null,
  warranty: null,
})

const Services: React.FunctionComponent<PropsWithChildren<any>> = ({
  children,
}) => {
  const services = {
    auth: new AuthService(),
    garage: new GarageService(),
    loading: new LoadingService(),
    note: new NoteService(),
    product: new ProductService(),
    task: new TaskService(),
    user: new UserService(),
    warranty: new WarrantyService(),
  }
  ServiceProvider.setServices(services)
  return (
    <ServiceContext.Provider value={services}>
      {children}
    </ServiceContext.Provider>
  )
}

export default Services
