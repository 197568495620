import React from 'react'
import { WarrantiesMeta } from '../../../../types'
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import { useWarrantyFilters } from '../../../../hooks'

const WarrantyFilterModal: React.FunctionComponent<{
  meta: WarrantiesMeta
  open: boolean
  onClose: () => void
}> = ({ meta, open, onClose }) => {
  const {
    garages,
    onSelectGarage,
    types,
    onSelectType,
    durations,
    onSelectDuration,
    statuses,
    onSelectStatus,
    canApply,
    applyFilters,
    canClear,
    clearFilters,
  } = useWarrantyFilters()

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minWidth="calc(100vh - 4rem)"
        maxWidth="1200px"
        paddingBottom="1rem"
      >
        <ModalHeader>Filter Warranties</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={SimpleGrid} columns={4} gap="1rem">
          <Card display="flex" flexDirection="column" gap="1rem">
            <Text fontSize="lg" color={textPrimary} fontWeight="bold">
              Filter by Garage
            </Text>
            <Checkbox
              colorScheme="brand"
              id="selectAllGarages"
              isChecked={garages.length === 0}
              onChange={() => onSelectGarage(undefined)}
            >
              All ({meta.garages.length})
            </Checkbox>
            {meta.garages.map((garage, index) => (
              <Checkbox
                key={index}
                colorScheme="brand"
                id={`garage-${garage.id}`}
                isChecked={garages.includes(garage.id)}
                onChange={() => onSelectGarage(garage)}
              >
                {garage.name}
              </Checkbox>
            ))}
          </Card>
          <Card display="flex" flexDirection="column" gap="1rem">
            <Text fontSize="lg" color={textPrimary} fontWeight="bold">
              Filter by Product Type
            </Text>
            <Checkbox
              colorScheme="brand"
              id="selectAllTypes"
              isChecked={types.length === 0}
              onChange={() => onSelectType(undefined)}
            >
              All ({meta.types.length})
            </Checkbox>
            {meta.types.map((type, index) => (
              <Checkbox
                key={index}
                colorScheme="brand"
                id={`type-${index}`}
                isChecked={types.includes(type)}
                onChange={() => onSelectType(type)}
              >
                {type}
              </Checkbox>
            ))}
          </Card>
          <Card display="flex" flexDirection="column" gap="1rem">
            <Text fontSize="lg" color={textPrimary} fontWeight="bold">
              Filter by Duration
            </Text>
            <Checkbox
              colorScheme="brand"
              id="selectAllDurations"
              isChecked={durations.length === 0}
              onChange={() => onSelectDuration(undefined)}
            >
              All ({meta.lengths.length})
            </Checkbox>
            {meta.lengths.map((duration, index) => (
              <Checkbox
                key={index}
                colorScheme="brand"
                id={`duration-${index}`}
                isChecked={durations.includes(duration)}
                onChange={() => onSelectDuration(duration)}
              >
                {duration} Month{duration === 1 ? '' : 's'}
              </Checkbox>
            ))}
          </Card>
          <Card display="flex" flexDirection="column" gap="1rem">
            <Text fontSize="lg" color={textPrimary} fontWeight="bold">
              Filter by Status
            </Text>
            <Checkbox
              colorScheme="brand"
              id="selectAllStatuses"
              isChecked={statuses.length === 0}
              onChange={() => onSelectStatus(undefined)}
            >
              All ({meta.statuses.length})
            </Checkbox>
            {meta.statuses.map((status, index) => (
              <Checkbox
                key={index}
                colorScheme="brand"
                id={`status-${status.key}`}
                isChecked={statuses.includes(status.key)}
                onChange={() => onSelectStatus(status)}
              >
                {status.title}
              </Checkbox>
            ))}
          </Card>
        </ModalBody>
        <ModalFooter as={Flex} justifyContent="flex-end" gap="1rem">
          {canClear ? (
            <Button
              colorScheme="red"
              onClick={() => clearFilters().then(onClose)}
            >
              Clear
            </Button>
          ) : null}
          {canApply ? (
            <Button
              variant="brand"
              onClick={() => applyFilters().then(onClose)}
            >
              Apply
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default WarrantyFilterModal
