import type {
  Garage,
  GarageResponse,
  GarageUpdate,
  GarageUpdateData,
} from '../types'
import { mapNote } from './notes'

export const mapGarage = (data: GarageResponse): Garage => {
  const { notes, created_at, updated_at, morph_type, ...attrs } = data
  return {
    ...attrs,
    notes: notes == null ? undefined : notes.map(mapNote),
    created_at: new Date(created_at),
    updated_at: new Date(updated_at),
    morph: { id: data.id, type: morph_type },
  }
}

export const mapGarageUpdate = (data: GarageUpdate): GarageUpdateData => {
  return {
    ...data,
  }
}
