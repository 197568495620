import React, { useCallback, useContext } from 'react'
import { useToast } from '@chakra-ui/react'
import { UseFormSetValue } from 'react-hook-form'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import FormCard from '../../molecules/shared/FormCard'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { MANAGEMENT, userCanAccess } from '../../../../utils'
import { useProduct } from '../../../../hooks'
import { productDetails, ProductDetailsFields } from '../../../../forms'
import GridForm from '../forms/GridForm'

const ProductDetails: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const user = useSelector(getUser)
  const { product, onUpdate } = useProduct()

  const initialise = useCallback(
    (setValue: UseFormSetValue<ProductDetailsFields>) => {
      setValue('name', product.name)
    },
    [product],
  )

  const renderFields = useCallback(
    (disabled: boolean, readOnly: boolean) => (
      <GridForm
        config={productDetails}
        isDisabled={disabled}
        isReadOnly={readOnly}
      />
    ),
    [],
  )

  const onSubmit = useCallback(
    (
      input: ProductDetailsFields,
      setError: (error: string) => void,
      onLoading: (loading: boolean) => void,
      onSaved: () => void,
    ) => {
      setError('')
      onLoading(true)
      services.product
        .update(product.id, {
          name: input.name,
        })
        .then((updated) => {
          onLoading(false)
          onUpdate(updated)
          onSaved()
          toast({
            title: 'Product Updated',
            description: `Your changes to ${updated.name} have been successfully saved`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          onLoading(false)
          setError('Sorry, there was a problem saving the details you entered')
        })
    },
    [services, toast, product, onUpdate],
  )

  return (
    <FormCard<ProductDetailsFields>
      title="Update Details"
      initialise={initialise}
      renderFields={renderFields}
      onSubmit={onSubmit}
      readOnly={!userCanAccess(user, MANAGEMENT)}
      fill
    />
  )
}

export default ProductDetails
