import * as React from 'react'
import { useCallback } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import {
  Box,
  Grid,
  GridItem,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import DetailHeader from '../../molecules/shared/DetailHeader'
import WarrantyCustomer from '../../organisms/warranties/WarrantyCustomer'
import WarrantyAddress from '../../organisms/warranties/WarrantyAddress'
import WarrantyVehicle from '../../organisms/warranties/WarrantyVehicle'
import WarrantyAgent from '../../organisms/warranties/WarrantyAgent'
import { useWarranty } from '../../../../hooks'
import WarrantyGarage from '../../organisms/warranties/WarrantyGarage'
import WarrantyInfo from '../../organisms/warranties/WarrantyInfo'
import WarrantyStatus from '../../organisms/warranties/WarrantyStatus'
import Tabs from '../../molecules/shared/Tabs'
import Card from '../../../../horizon/components/card/Card'
import NotesTable from '../../organisms/notes/NotesTable'
import { Note, Task } from '../../../../types'
import { sortByDate } from '../../../../utils'
import TasksTable from '../../organisms/tasks/TasksTable'
import { Link as RouterLink } from 'react-router-dom'
import { reverse } from 'named-urls'

const WarrantyPage: React.FunctionComponent = () => {
  const { warranty, onUpdate } = useWarranty()

  const handleNewNote = useCallback(
    (note: Note) => {
      onUpdate({ ...warranty, notes: [note, ...warranty.notes] })
    },
    [warranty, onUpdate],
  )

  const handleUpdateTask = useCallback(
    (task: Task, deleted?: boolean) => {
      const tasks = [...warranty.tasks]
      const index = tasks.findIndex(({ id }) => id === task.id)
      if (index >= 0) {
        if (deleted) {
          tasks.splice(index, 1)
        } else {
          tasks[index] = task
        }
      } else {
        tasks.push(task)
      }
      onUpdate({ ...warranty, tasks: sortByDate(tasks, 'due_at', true) })
    },
    [warranty, onUpdate],
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Warranties', link: routes.warranties.index },
        ...(warranty ? [{ label: warranty.policy_number }] : []),
      ]}
      title={warranty ? warranty.policy_number : 'Loading...'}
    >
      {warranty ? (
        <Box>
          <Grid marginBottom="20px" templateColumns="repeat(6, 1fr)" gap="20px">
            <GridItem colSpan={4}>
              <DetailHeader
                title={`Policy: ${warranty.policy_number}`}
                subtitle={warranty.full_name}
                additional={
                  warranty.renewed ? (
                    <>
                      Renewed from:{' '}
                      <Link
                        as={RouterLink}
                        to={reverse(routes.warranties.detail, {
                          warrantyId: warranty.renewed.id,
                        })}
                      >
                        {warranty.renewed.policy_number}
                      </Link>
                    </>
                  ) : undefined
                }
              />
            </GridItem>
            <GridItem colSpan={2} />
            <GridItem colSpan={2}>
              <WarrantyAgent />
            </GridItem>
            <GridItem colSpan={2}>
              <WarrantyGarage />
            </GridItem>
            <GridItem colSpan={2}>
              <WarrantyStatus />
            </GridItem>
            <GridItem colSpan={6}>
              <Tabs
                tabs={[
                  {
                    title: 'Details',
                    slug: 'details',
                    onRender: () => (
                      <Grid
                        marginBottom="20px"
                        templateColumns="repeat(2, 1fr)"
                        gap="20px"
                      >
                        <GridItem display="flex" flexDirection="column">
                          <WarrantyCustomer />
                        </GridItem>
                        <GridItem display="flex" flexDirection="column">
                          <WarrantyInfo />
                        </GridItem>
                        <GridItem display="flex" flexDirection="column">
                          <WarrantyAddress />
                        </GridItem>
                        <GridItem display="flex" flexDirection="column">
                          <WarrantyVehicle />
                        </GridItem>
                      </Grid>
                    ),
                  },
                  {
                    title: 'Notes',
                    slug: 'notes',
                    onRender: () => (
                      <Card
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="1rem"
                      >
                        <Text
                          fontSize="xl"
                          color={textPrimary}
                          fontWeight="bold"
                          paddingX="0.5rem"
                        >
                          Notes
                        </Text>
                        <NotesTable
                          morph={warranty.morph}
                          notes={warranty.notes}
                          onCreated={handleNewNote}
                        />
                      </Card>
                    ),
                  },
                  {
                    title: 'Tasks',
                    slug: 'tasks',
                    onRender: () => (
                      <Card
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="1rem"
                      >
                        <Text
                          fontSize="xl"
                          color={textPrimary}
                          fontWeight="bold"
                          paddingX="0.5rem"
                        >
                          Tasks
                        </Text>
                        <TasksTable
                          morph={warranty.morph}
                          tasks={warranty.tasks}
                          onUpdated={handleUpdateTask}
                        />
                      </Card>
                    ),
                  },
                ]}
              />
            </GridItem>
          </Grid>
        </Box>
      ) : null}
    </AdminTemplate>
  )
}

export default WarrantyPage
