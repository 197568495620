import React, { useCallback } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import FormCard from '../../molecules/shared/FormCard'
import { DATE_FORMAT, formatOptional } from '../../../../utils'
import { useWarranty } from '../../../../hooks'
import GridForm from '../forms/GridForm'
import { warrantyInfo, WarrantyInfoFields } from '../../../../forms'

const WarrantyInfo: React.FunctionComponent = () => {
  const { warranty } = useWarranty()

  const initialise = useCallback(
    (setValue: UseFormSetValue<WarrantyInfoFields>) => {
      setValue('policyNumber', warranty.policy_number)
      setValue('type', warranty.type)
      setValue(
        'purchaseDate',
        formatOptional(warranty.purchased_on, DATE_FORMAT) ?? '-',
      )
      setValue(
        'length',
        `${warranty.length} month${warranty.length === 1 ? '' : 's'}`,
      )
      setValue('claimLimit', warranty.claim_limit)
      setValue(
        'expiryDate',
        formatOptional(warranty.expires_on, DATE_FORMAT) ?? '-',
      )
    },
    [warranty],
  )

  const renderFields = useCallback(
    (disabled: boolean, readOnly: boolean) => (
      <GridForm
        config={warrantyInfo}
        isDisabled={disabled}
        isReadOnly={readOnly}
      />
    ),
    [],
  )

  return (
    <FormCard<WarrantyInfoFields>
      title="Warranty Information"
      initialise={initialise}
      renderFields={renderFields}
      onSubmit={() => {}}
      readOnly={true}
    />
  )
}

export default WarrantyInfo
